import React from "react";
import { ThreeDots } from "react-loader-spinner";

const Spinner = () => {
  return <ThreeDots color="#484d5e" height={100} width={100} />;
};

export const AccentSpinner = () => {
  return <ThreeDots color="white" height={18} width={40} />;
};

export default Spinner;
