export const frequencies = ["DAILY", "WEEKLY", "MONTHLY"];
export const _endDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
export const endDays = [
  { name: "MONDAY", value: 1 },
  { name: "TUESDAY", value: 2 },
  { name: "WEDNESDAY", value: 3 },
  { name: "THURSDAY", value: 4 },
  { name: "FRIDAY", value: 5 },
  { name: "SATURDAY", value: 6 },
  { name: "SUNDAY", value: 7 },
];
export const maxCheckCounts = 26;

export function timeArray(start: string, end: string): string[] {
  const startParts = start.split(":");
  const endParts = end.split(":");

  const startTime = parseInt(startParts[0]) * 60 + parseInt(startParts[1]);
  const endTime = parseInt(endParts[0]) * 60 + parseInt(endParts[1]);

  const result: string[] = [];

  for (let time = startTime; time <= endTime; time += 30) {
    result.push(timeString(time));
  }

  return result;
}

function timeString(time: number): string {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;

  const formattedHours = hours < 10 ? "0" + hours : hours.toString();
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString();

  return formattedHours + ":" + formattedMinutes;
}
