import React, { useCallback, useEffect } from "react";
import Box from "../../components/Box";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUser, setCompanyEmail } from "../../utils/storage";
import { RootState } from "../../reducers/rootReducer";
import { loadAllCompanies } from "../../lib/company/companySlice";
import { LoadingState } from "../../lib/company/companyTypes";
import Select from "../../components/Select";

const SelectCompanyScreen = () => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();

  const { handleSubmit, control } = useForm();

  const { companies, companiesLoadingState } = useSelector((state: RootState) => state.company);

  const fetched = companiesLoadingState === LoadingState.FETCHED;

  const onSubmit = useCallback((values: any) => {
    const companyEmail = values?.company?.value;
    if (companyEmail) {
      setCompanyEmail(companyEmail);
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const user = getUser();
    if (user && user.superAdmin) {
      if (!fetched) dispatch(loadAllCompanies());
    } else {
      window.location.href = "/";
    }
  }, [dispatch, fetched]);

  return (
    <div id="login" style={{ height }}>
      <div className="login-box">
        <Box header="Please select a company">
          <form onSubmit={handleSubmit(onSubmit)} className="form" method="post">
            <div>
              <Controller
                name="company"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    placeholder="Company"
                    searchable
                    options={companies.map((company) => ({
                      label: company.companyName,
                      value: company.companyManagerEmail,
                    }))}
                    withAll={false}
                  />
                )}
                control={control}
              />
            </div>

            <div>
              <input type="submit" className="btn-primary btn-xl login-button" value="Select" />
            </div>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default SelectCompanyScreen;
