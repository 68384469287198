import React, { useEffect, useMemo, useCallback, useState } from "react";
import Container from "../../components/Container";
import Row from "../../components/Row";
import Box from "../../components/Box";
import ResponsiveSelect from "../../components/ResponsiveSelect";

import { FaCommentDots, FaPlus, FaTrash } from "react-icons/fa";
import { BsGripHorizontal, BsGripVertical } from "react-icons/bs";

import { endDays, frequencies, maxCheckCounts, timeArray } from "./checklist-editor-utils";

import { range } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";

import { toast } from "react-toastify";
import { storageRef } from "../../firebase";
import { map } from "rxjs/operators";
import { useLocation, useParams } from "react-router-dom";
import { loadChecksBySiteAndChecklistReference } from "../../lib/firebase/company";
import { LoadingState } from "../../lib/company/companyTypes";
import Modal from "../../components/Modal";
import { useConfirmBrowserExit } from "./useConfirmBrowserExit";
import RouteLeavingGuard from "../../components/RouteLeavingGuard";
import { useHistory } from "react-router-dom";
import { ChecksData } from "./checklist-data";
import EditableChecklistTile from "./EditableChecklistTile";
import EditDescriptionModal from "./EditDescriptionModal";
const { v4: uuidv4 } = require("uuid");

interface CustomData {
  name?: string | undefined;
  frequency?: string | undefined;
  endTime?: string | undefined;
  endDay?: string | undefined;
  checkListCount?: number | undefined;
  sitesId?: string[] | undefined;
  checks: ChecksData[] | undefined;
}

function convertDataToCsv(checklist?: CustomData, checklistId?: string) {
  if (!checklist) {
    return [];
  }
  let data = [];
  const generatedUuid = uuidv4();
  for (let x = 0; x < checklist.sitesId!.length; x++) {
    for (let y = 0; y < (checklist.checks ?? []).length; y++) {
      const indexedChecklist = (checklist.checks ?? [])[y];
      console.log("checklist", checklist.endDay);
      data.push(
        [
          checklist.sitesId![x].toString(),
          checklistId ?? generatedUuid,
          checklist.name!.toString(),
          (checklist.frequency ?? "").toString().toLowerCase(),
          checklist.endTime ?? "23:59",
          checklist.frequency!.toUpperCase() == "MONTHLY" ? 31 : checklist.frequency!.toUpperCase() == "WEEKLY" ? checklist.endDay : undefined,
          `"${indexedChecklist.name ?? ""}"`,
          `"${indexedChecklist.helpText ?? "-"}"`,
          `"${indexedChecklist.description?.replaceAll('"', '""') ?? ""}"`,
        ].join(";") + "\n"
      );
    }
  }
  return data;
}

function CanGenerate(checklist?: CustomData) {
  if (checklist?.sitesId && checklist.name && checklist.frequency && checklist.checks) {
    if (checklist?.frequency == "WEEKLY" && !checklist.endDay) {
      return false;
    }
    return true;
  }

  if ((checklist?.checks ?? []).length <= 0) {
    return false;
  }
  
  return false;
}
const CheckListEditorScreen = () => {
  const params = useParams();
  const prevLocation = useLocation().pathname;
  const location = useLocation();
  const history = useHistory();

  const useUploadFile = (onComplete: () => void, onError: (error: Error) => void) => {
    const [progress, setProgress] = useState(0);

    const upload = useCallback(
      (filePath: string, file: File) => {
        const uploadTask = storageRef.child(filePath).put(file);
        uploadTask.on(
          "state_changed",
          (snapshot: any) => {
            var uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("upload progress", uploadProgress);
            setProgress(uploadProgress);
          },
          onError,
          onComplete
        );
      },
      [onComplete, onError]
    );
    return {
      progress,
      upload,
    };
  };

  const { upload } = useUploadFile(
    () => {
      toast.info("Checklist has been uploaded.");
      setIsConfirmLeaveOpen(true);
      setChecklistData({
        checks: range(0, 5).map((d, index) => {
          return {
            name: "",
            description: "",
            helpText: "",
            index: index,
          };
        }),
      });
      setTimeout(() => {
        history.replace("/checklist-editor");
        setIsConfirmLeaveOpen(false);
      }, 1000);
    },
    (error) => toast.error(error.message)
  );

  const onSubmit = async () => {
    setIsLoading(true);

    const convertedCsv = convertDataToCsv(checklistData, (params as any).checklist);

    const csvRows = convertedCsv!;

    const csvData = new Blob(csvRows, { type: "text/csv;charset=utf-8;" });
    const fileByCsvData = new File([csvData], `checklist_${checklistData?.name}.csv`, { type: "text/csv;" });
    console.log(csvData);
    upload(`checklist_${checklistData?.name}.csv`, fileByCsvData);

    setIsLoading(false);
  };

  const [checklistData, setChecklistData] = useState<CustomData | undefined>({
    checks: range(0, 5).map((d, index) => {
      return {
        name: "",
        description: "",
        helpText: "",
        index: index,
      };
    }),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isConfirmLeaveOpen, setIsConfirmLeaveOpen] = useState<boolean>(false);
  const [isDescriptionEditOpen, setIsDescriptionEditOpen] = useState<boolean>(false);
  const [selectedCheck, setSelectedCheck] = useState<number | undefined>(undefined);

  const sitesLoadingState = useSelector((state: RootState) => state.company.sitesLoadingState);
  const sites = useSelector((state: RootState) => state.company.sites);

  useEffect(() => {
    const siteId = (params as any).site;
    const checklistId = (params as any).checklist;
    if (siteId && checklistId) {
      setIsFetching(true);
      loadChecksBySiteAndChecklistReference(siteId, checklistId)
        .get()
        .then((doc: any) => {
          const data = doc.data();
          if (data) {
            setChecklistData({
              name: data.list as any,
              frequency: data.recurrenceType as any,
              endTime: data.endTime.time as any,
              endDay: data.endTime.day as any,
              checkListCount: data.checks.length as any,
              sitesId: [siteId],
              checks: data.checks.map((check: any, index: any) => ({
                index: index,
                name: check.item,
                description: check.description ?? "",
                helpText: check.help,
              })),
            });
          }

          setIsFetching(false);
        })
        .catch((error: any) => setIsFetching(false));
    } else {
      setIsFetching(false);
    }
  }, [params]);

  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  const handleSort = () => {
    let _checklistItems = checklistData?.checks ?? [];

    console.log(dragItem.current + " - " + dragOverItem.current);
    const finalDragItem = {
      ..._checklistItems[dragItem.current],
      index: dragOverItem.current,
    };
    const finalDragOverItem = {
      ..._checklistItems[dragOverItem.current],
      index: dragOverItem.current,
    };

    _checklistItems.splice(dragItem.current, 1);
    _checklistItems.splice(dragOverItem.current, 0, finalDragItem);

    console.log(_checklistItems);

    dragItem.current = null;
    dragOverItem.current = null;
    const data = checklistData;

    setChecklistData(undefined);

    setChecklistData({ ...data, checks: _checklistItems } as CustomData);
  };
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  if (sites.length <= 0) {
    return <></>;
  }
  if (isFetching) {
    return <></>;
  }
  return isConfirmLeaveOpen ? (
    <></>
  ) : (
    <>
      {isDescriptionEditOpen && (
        <EditDescriptionModal
          isOpen={isDescriptionEditOpen}
          onChange={(data: any) => {
            checklistData!.checks![selectedCheck ?? 0] = {
              ...((checklistData! as any).checks[selectedCheck!] as any),
              description: data,
            };
            setChecklistData({
              ...checklistData,
            } as CustomData);
          }}
          onClose={() => setIsDescriptionEditOpen(false)}
          check={(checklistData as any).checks[selectedCheck ?? 0]}
        />
      )}
      <div className="mb-5">
        <Box header="Settings">
          <div className="col col-lg-12 p-0">
            <div className="row row-no-padding px-2 py-3 col-lg-12 m-0">
              <div className="col px-1">
                <label>Site:</label>
                <ResponsiveSelect
                  withAll
                  placeholder="Site"
                  defaultValue={(checklistData?.sitesId ?? []).map((indexed) => {
                    const site = sites.find((d) => (d.reference ?? "") == indexed);
                    if (site) {
                      return {
                        value: indexed,
                        label: site?.companyName ?? "test",
                      };
                    }
                  })}
                  isMulti
                  onChange={(data) => {
                    console.log(JSON.stringify(data));
                    if (data.length > 0 && data[0].label == "All") {
                      setChecklistData({ ...checklistData, sitesId: (sites ?? []).map((d) => d.reference) } as CustomData);
                    } else {
                      setChecklistData({ ...checklistData, sitesId: (data ?? []).map((d: any) => d.value) } as CustomData);
                    }
                  }}
                  options={sites.map((data) => ({
                    value: data.reference,
                    label: `${data.companyName}`,
                  }))}
                />
              </div>
              <div className="col px-1">
                <label>Checklist name:</label>
                <input
                  style={{
                    maxHeight: 38,
                  }}
                  placeholder="Checklist name"
                  className="w-100 h-100"
                  type="text"
                  defaultValue={checklistData?.name}
                  onChange={(data) => setChecklistData({ ...checklistData, name: data.currentTarget.value } as CustomData)}
                />
              </div>
              <div className="col px-1">
                <label>Frequency:</label>
                <ResponsiveSelect
                  placeholder="Frequency"
                  onChange={(data) => setChecklistData({ ...checklistData, frequency: data.value } as CustomData)}
                  withAll={false}
                  defaultValue={
                    checklistData?.frequency && {
                      value: checklistData?.frequency,
                      label: checklistData?.frequency,
                    }
                  }
                  options={frequencies.map((data) => ({
                    value: data,
                    label: `${data}`,
                  }))}
                />
              </div>
              <div className="col px-1">
                <label>End time:</label>
                <ResponsiveSelect
                  placeholder="End time"
                  withAll={false}
                  onChange={(data) => setChecklistData({ ...checklistData, endTime: data.value } as CustomData)}
                  defaultValue={
                    checklistData?.endTime && {
                      value: checklistData?.endTime,
                      label: checklistData?.endTime,
                    }
                  }
                  options={[
                    ...timeArray("00:00", "23:59").map((data) => ({
                      value: data,
                      label: `${data}`,
                    })),
                    {
                      value: "23:59",
                      label: "23:59",
                    },
                  ]}
                />
              </div>
              <div className="col px-1">
                <label>End day:</label>

                <ResponsiveSelect
                  placeholder="End day"
                  withAll={false}
                  disabled={checklistData?.frequency != "WEEKLY"}
                  onChange={(data) => setChecklistData({ ...checklistData, endDay: data.value } as CustomData)}
                  defaultValue={{
                    value: parseInt(checklistData?.endDay ?? ""),
                    label: endDays.find((d) => d.value == parseInt(checklistData?.endDay ?? "-1"))?.name ?? "",
                  }}
                  options={endDays.map((data, index) => ({
                    value: data.value,
                    label: `${data.name}`,
                  }))}
                />
              </div>
            </div>
          </div>
        </Box>
      </div>
      <Box header="Checklist">
        <div className="p-0 col-lg-12 row">
          <table className="table checklist-table">
            <thead>
              <tr>
                <th>Index</th>
                <th>Check</th>
                <th>Description</th>
                <th>Help text</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(checklistData?.checks ?? []).map((data, index) => (
                <tr
                  key={index}
                  draggable
                  onDragStart={(e) => (dragItem.current = index)}
                  onDragEnter={(e) => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                >
                  <EditableChecklistTile
                    key={index}
                    openModal={() => {
                      setSelectedCheck(index);
                      setIsDescriptionEditOpen(true);
                    }}
                    onDelete={(index) => {
                      const list = checklistData?.checks ?? [];
                      list.splice(index, 1);
                      setChecklistData({
                        ...checklistData,
                        checks: list,
                      } as CustomData);
                    }}
                    check={{
                      ...data,
                      index: index,
                    }}
                    onChange={(data) => {
                      checklistData!.checks![index] = {
                        ...data,
                      };
                      setChecklistData({
                        ...checklistData,
                      } as CustomData);
                    }}
                  />
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3 d-flex">
            <span className="my-auto flex-grow-1 ">*Please do not use the semicolon symbol (;)</span>
            <span
              className="btn-primary"
              onClick={() => {
                const count = (checklistData?.checks?.length ?? 0) + 1;
                setChecklistData({
                  ...checklistData,
                  checkListCount: count,
                  checks: [
                    ...(checklistData?.checks ?? []),
                    {
                      index: count,
                      helpText: "",
                      description: "",
                      name: "",
                    },
                  ],
                });
              }}
            >
              <FaPlus />
            </span>
          </div>
        </div>
      </Box>
      <div className="row col-lg-12 justify-content-end float-right gx-5">
        <input
          type="submit"
          className="btn-primary btn-xl login-button mt-3"
          value={"Discard all changes"}
          onClick={() => {
            window.location.reload();
          }}
        />
        {CanGenerate(checklistData) && (
          <input
            disabled={isLoading}
            type="submit"
            className="btn-primary btn-xl login-button mt-3 ml-3"
            value={isLoading ? "Sending..." : "Submit"}
            onClick={onSubmit}
          />
        )}
      </div>
      <RouteLeavingGuard
        navigate={function (path: string): void {
          window.location.href = path;
        }}
        shouldBlockNavigation={function (location): boolean {
          if (location.pathname == "/checklist-editor") {
            return false;
          }
          return true;
        }}
      />
    </>
  );
};

export default CheckListEditorScreen;
