import { db } from '../../firebase';

export const loadAllFailedDeliveries = (siteId: string) =>
  db
    .collectionGroup('delivery_checks')
    .where('siteReference', '==', siteId)
    .where('passed', '==', false)
    .where('resolution', '==', null);

export const loadAllFailedTemperatureChecks = (type: string, siteId: string) =>
  db
    .collectionGroup(`${type}_checks`)
    .where('siteReference', '==', siteId)
    .where('passed', '==', false);

export const loadAllFailedChecklistItems = (type: string, siteId: string) =>
  db
    .collectionGroup(`${type}_checklist_items`)
    .where('siteReference', '==', siteId)
    .where('state', '==', 'failed')
    .where('resolution', '==', null);

export const loadChecks = (siteId: string, type: string, date: string) =>
  db
    .collection(`${type}_checklists`)
    .doc(date)
    .collection(`${type}_checklist_entries`)
    .doc(siteId)
    .collection(`${type}_checklist_lists`);

export const loadListByDate = (
  siteId: string,
  date: string,
  checklistReference: string,
  type: string,
) => loadChecks(siteId, type, date).doc(checklistReference);

export const loadIssueEntries = ({ siteId }: { siteId: string }) =>
  db.collectionGroup(`issue_entries`).where('siteId', '==', siteId);
