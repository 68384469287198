import { of, defer } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
  availableMigrationsLoaded,
  loadAvailableMigrations,
} from './migrationsSlice';
import { loadMigrations } from '../firebase/migrations';

const loadAvailableMigrationsEpic = (action$) =>
  action$.pipe(
    ofType(loadAvailableMigrations.toString()),
    mergeMap(() =>
      defer(async () => {
        const availableMigrations = await loadMigrations().get();
        const migrations = [];
        availableMigrations.forEach((doc) => {
          migrations.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return migrations;
      }).pipe(
        map(availableMigrationsLoaded),
        catchError(() => {
          return of({ type: 'error' });
        }),
      ),
    ),
  );
const companyEpics = combineEpics(loadAvailableMigrationsEpic);

export default companyEpics;
