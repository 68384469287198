import moment from 'moment';
import { db } from '../../firebase';

const convertFirebaseTimestampToDateString = (results: Array<any>) =>
  results.map((r) => ({
    ...r,
    timestamp: moment(r.timestamp.toDate()).format('YYYY-MM-DD'),
  }));

const getRecentDaysRange = () => ({
  start: moment().subtract(7, 'days').startOf('day').toDate(),
  end: moment().endOf('day').toDate(),
});

const getCalendarEntriesInRange = ({
  siteId,
  start,
  end,
}: {
  siteId: string;
  start: Date;
  end: Date;
}) =>
  db
    .collectionGroup('calendar_entries')
    .where('siteId', '==', siteId)
    .where('timestamp', '>=', start)
    .where('timestamp', '<=', end);

const getAllCalendarEntriesForRecentDays = ({ siteId }: { siteId: string }) =>
  getCalendarEntriesInRange({
    siteId,
    ...getRecentDaysRange(),
  });

const loadCalendarCache = ({ siteId }: { siteId: string }) =>
  db.collectionGroup('calendar_entries').where('siteId', '==', siteId);

export {
  getAllCalendarEntriesForRecentDays,
  loadCalendarCache,
  convertFirebaseTimestampToDateString
};
