import React, { useEffect, useMemo, useCallback } from "react";
import Container from "../../components/Container";
import Row from "../../components/Row";
import Box from "../../components/Box";
import Select from "../../components/Select";
import CheckListTable from "./CheckListTable";
import { useParams, useHistory } from "react-router-dom";
import LocationAndRegionFilter from "../../components/LocationAndRegionFilter";
import { ISSUE_GROUP_LABELS } from "../../common/issueTypes";
import { capitalize } from "lodash";
import { setFilter } from "../../lib/filter/filterSlice";
import { useDispatch } from "react-redux";
import useShowSpinner from "../../hooks/useShowSpinner";
import useSelectedIssues from "./useSelectedIssues";

const CheckListScreen = () => {
  const { issue } = useParams<{ issue: string }>();
  const dispatch = useDispatch();

  const history = useHistory();

  const issueTypes = useMemo(
    () =>
      Object.values(ISSUE_GROUP_LABELS).map((label: string) => {
        return {
          label,
          value: label,
        };
      }),
    []
  );

  useEffect(() => {
    if (!issue) {
      dispatch(setFilter({ issueType: "All" }));
    } else {
      dispatch(setFilter({ issueType: issue }));
    }
  }, [issue, dispatch, history]);

  const handleSelectIssueType = useCallback(
    ({ label }: { label: any }) => {
      history.push("/checklist/" + label);
    },
    [history]
  );

  const selectedIssues = useSelectedIssues();
  const showSpinner = useShowSpinner();

  const issuesLength = selectedIssues.length;
  const showLength = Math.min(5000, issuesLength);

  return (
    <Container>
      <Row>
        <div className="col-lg-12">
          <div className="select-group mg-bottom-20">
            <Select
              placeholder="Issue"
              onChange={handleSelectIssueType}
              options={issueTypes}
              defaultValue={{ label: capitalize(issue), value: issue }}
            />
            <LocationAndRegionFilter />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-12">
          <Box loading={showSpinner}>
            <div className="checklists">
              <CheckListTable issues={selectedIssues.slice(0, showLength)} />
              <div className="checklists__footer">
                Displaying {showLength} of {issuesLength} items
              </div>
            </div>
          </Box>
        </div>
      </Row>
    </Container>
  );
};

export default CheckListScreen;
