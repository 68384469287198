import { RootState } from '../reducers/rootReducer';
import { useSelector } from 'react-redux';

const useSelectedSiteReferences = (): Array<string> => {
  const {
    selectedRegion,
    selectedLocation,
    allSiteReferences,
    regions,
  } = useSelector((state: RootState) => ({
    selectedRegion: state.filter.region,
    selectedLocation: state.filter.location,
    allSiteReferences: state.company.allSiteReferences,
    regions: state.company.regions,
  }));
  if (selectedRegion === null && selectedLocation === null) {
    return allSiteReferences;
  } else if (selectedRegion !== null && selectedLocation === null) {
    return regions[selectedRegion].siteReferences;
  }
  return [selectedLocation as string];
};

export default useSelectedSiteReferences;
