import React from 'react';
import moment, { Moment } from 'moment';
import { Day, DaysBySite } from '../../lib/calendar/calendarSlice';
import c from 'classnames';
import { NamesBySite } from '../../lib/company/companyTypes';
import { range } from 'lodash';

export enum DayTypeClassNameType {
  EMPTY = 'empty',
  PASSED = 'passed',
  FAILED = 'failed',
  FAILED_AND_RESOLVED = 'resolved',
}

interface CircleProps {
  items: Day;
}

export const Circle: React.FC<CircleProps> = ({ items }) => {
  const className: DayTypeClassNameType = dayTypeClass(items);

  return (
    <div
      data-items={JSON.stringify(items)}
      className={c(['day-circle', className])}
    >
      <div className="inner-circle" />
    </div>
  );
};

Circle.defaultProps = {
  items: { items: 0, failed: 0, resolved: 0 },
};

const dayTypeClass = ({
  items,
  failed,
  resolved,
}: Day): DayTypeClassNameType => {
  if (items === 0) {
    return DayTypeClassNameType.EMPTY;
  }

  if (items > 0 && failed === 0) {
    return DayTypeClassNameType.PASSED;
  }

  if (failed === resolved) {
    return DayTypeClassNameType.FAILED_AND_RESOLVED;
  }

  return DayTypeClassNameType.FAILED;
};

interface SevenDaysTableRowProps {
  siteReference: string;
  daysToRender: Array<string>;
  name: string;
  week: any;
}

const SevenDaysTableRow: React.FC<SevenDaysTableRowProps> = ({
  siteReference,
  daysToRender,
  name,
  week,
}) => {
  return (
    <tr data-sitereference={siteReference}>
      <td>{name}</td>
      {daysToRender.map((date: string) => {
        const itemsOnDate = week.find(
          ({ timestamp }: { timestamp: string }) => timestamp === date,
        );

        return (
          <td key={date}>
            <Circle items={itemsOnDate} />
          </td>
        );
      })}
    </tr>
  );
};

interface SevenDaysTableProps {
  daysBySite: DaysBySite;
  namesBySite: NamesBySite;
}

const SevenDaysTable: React.FC<SevenDaysTableProps> = ({
  daysBySite,
  namesBySite,
}) => {
  const dates: Array<Moment> = range(6, -1).map((i: number) => {
    return moment().subtract(i, 'days');
  });

  const headers = dates.map((date: Moment) => {
    return date.format('ddd');
  });

  const daysToRender = dates.map((date: Moment) => {
    return date.format('YYYY-MM-DD');
  });

  return (
    <table className="table seven-days-table">
      <thead>
        <tr>
          <th key={0}>Location</th>
          {headers.map((day: string) => (
            <th key={day}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(daysBySite).map((siteReference: any) => (
          <SevenDaysTableRow
            daysToRender={daysToRender}
            siteReference={siteReference}
            key={siteReference}
            name={namesBySite[siteReference]}
            week={daysBySite[siteReference]}
          />
        ))}
      </tbody>
    </table>
  );
};

export default SevenDaysTable;
