import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Roles } from "../lib/login/loginSlice";
import { RootState } from "../reducers/rootReducer";

const useHasAccessToProductUpload = () => {
  const { role } = useSelector((state: RootState) => state.login);
  const { company: companySettings } = useSelector((state: RootState) => state.company);
 
  return useMemo(() => role === Roles.CompanyManager && !!companySettings?.allergens, [companySettings, role]);
};

export default useHasAccessToProductUpload;
