import { combineEpics } from 'redux-observable';
import companyEpics from '../lib/company/companyEpics';
import issuesEpics from '../lib/issues/issuesEpics';
import loginEpics from '../lib/login/loginEpics';
import calendarEpics from '../lib/calendar/calendarEpics';
import migrationEpic from '../lib/migrations/migrationsEpic';

const rootEpic = combineEpics(
  companyEpics,
  loginEpics,
  issuesEpics,
  calendarEpics,
  migrationEpic,
);

export default rootEpic;
