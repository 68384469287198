import React from "react";
import { PropsWithClassAndChildren } from "../common/types";
import c from "classnames";

const Row: React.FC<PropsWithClassAndChildren<{}>> = ({
  children,
  className
}) => {
  return <div className={c(["row", className])}>{children}</div>;
};

export default Row;
