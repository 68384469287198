import React, { useEffect, useMemo, useState } from "react";
import Box from "../../components/Box";
import StatusBox from "../../components/StatusBox";
import IssueBreakDownTable from "./IssueBreakDownTable";
import Container from "../../components/Container";
import Row from "../../components/Row";
import SevenDaysTable from "./SevenDaysTable";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import LocationAndRegionFilter from "../../components/LocationAndRegionFilter";
import useShowSpinner from "../../hooks/useShowSpinner";
import useFilteredSites from "../../hooks/useFilteredSites";
import { pick, values, sum } from "lodash";
import useSelectedSitesCache from "./useSelectedSitesCache";
import Modal from "../../components/Modal";
import { getNonMigratedSites } from "../../utils/selectors/siteSelectors";
import MigrationList from "./MigrationList";
import { LoadingState } from "../../lib/company/companyTypes";
import Calendar from "./Calendar";
import { getUser } from "../../utils/storage";
import ResolveIssues from "./ResolveIssues";
import ChecklistsTable from "./ChecklistsTable";
import useHasAccessToProductUpload from "../../hooks/useHasAccessToProductUpload";
import useHasAccessToEditChecklists from "../../hooks/useHasAccessToEditChecklists";

export default function HomeScreen() {
  const [migrationsModal, setMigrationsModal] = useState(false);

  const nonMigratedSites = useSelector(getNonMigratedSites);

  const hasAccessToEditChecklists = useHasAccessToEditChecklists();

  const { namesBySite, weeklyIssues, calendarEntries, issueCacheStatus, issuesFetching, sitesLoadingState } = useSelector((state: RootState) => {
    return {
      namesBySite: state.company.namesBySite,
      weeklyIssues: state.calendar.weeklyIssues,
      calendarEntries: state.calendar.calendarEntries,
      issuesFetching: state.issues.fetching,
      issueCacheStatus: state.issues.issueCacheStatus,
      sitesLoadingState: state.company.sitesLoadingState,
    };
  });

  const filteredSiteReferences = useFilteredSites();

  const selectedSitesCache = useSelectedSitesCache();

  const allIssueCount = useMemo(() => {
    return sum(values(selectedSitesCache));
  }, [selectedSitesCache]);

  const filteredWeeklyIssues = useMemo(() => {
    return pick(weeklyIssues, filteredSiteReferences);
  }, [filteredSiteReferences, weeklyIssues]);

  const filteredCalendarEntries = useMemo(() => {
    const result: any = {};
    calendarEntries.forEach((day: any) => {
      if (filteredSiteReferences.includes(day.siteId)) {
        if (!result[day.timestamp]) {
          result[day.timestamp] = { items: 0, failed: 0, resolved: 0 };
        }
        result[day.timestamp].items += day.items;
        result[day.timestamp].failed += day.failed;
        result[day.timestamp].resolved += day.resolved;
      }
    }, {});
    return result;
  }, [filteredSiteReferences, calendarEntries]);

  const showSpinner = useShowSpinner();

  useEffect(() => {
    if (nonMigratedSites.length > 0) {
      setMigrationsModal(true);
    } else {
      setMigrationsModal(false);
    }
  }, [nonMigratedSites]);

  console.log("issues:", issuesFetching, "sitesLoadingState", sitesLoadingState, "issueCacheStatus", issueCacheStatus);
  const loading =
    issuesFetching ||
    sitesLoadingState === LoadingState.LOADING ||
    issueCacheStatus === LoadingState.LOADING ||
    issueCacheStatus === LoadingState.IDLE;

  const user = getUser();

  return (
    <Container>
      <Row>
        <div className="col-lg-12">
          <div className="select-group mg-bottom-20">
            <LocationAndRegionFilter />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-lg-6 mg-top-lg-20">
          <Box header="Status" loading={loading}>
            <StatusBox issueCount={allIssueCount} />
          </Box>
        </div>
        <div className="col-lg-6 mg-top-lg-20">
          <Box header="Issue breakdown" loading={loading}>
            <IssueBreakDownTable issues={selectedSitesCache} />
          </Box>
        </div>
      </Row>
      <Row>
        <div className="col-lg-6 mg-top-20">
          <Box header="7 days" loading={showSpinner}>
            <SevenDaysTable daysBySite={filteredWeeklyIssues} namesBySite={namesBySite} />
          </Box>
          {user?.superAdmin && (
            <div className="mg-top-20">
              <Box header="Resolve Issues" loading={showSpinner}>
                <ResolveIssues />
              </Box>
            </div>
          )}
          {hasAccessToEditChecklists && (
            <div className="mg-top-20 ">
              <Box header="Checklists" loading={showSpinner}>
                <ChecklistsTable />
              </Box>
            </div>
          )}
        </div>
        <div className="col-lg-6 mg-top-20">
          <Box header="Calendar" loading={showSpinner}>
            <Calendar days={filteredCalendarEntries} />
          </Box>
        </div>
      </Row>

      <Modal visible={migrationsModal} onClose={() => setMigrationsModal(false)} title="Migrations">
        <h3 className="mg-bottom-20">
          The reporting system is currently being upgraded, full functionality will not be available. Please contact Complete Food Safety for further
          information.
        </h3>
        <MigrationList sites={nonMigratedSites} />
      </Modal>
    </Container>
  );
}
