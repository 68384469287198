import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import dynamicMiddlewares, { addMiddleware } from "redux-dynamic-middlewares";
import rootReducer from "./reducers/rootReducer";
import rootEpic from "./epics/rootEpic";
import moment from "moment";

const epicMiddleware = createEpicMiddleware();
const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loggingMiddleware = (store: { getState: () => any }) => (next: (arg0: any) => any) => (action: any) => {
  console.group("Action: " + action.type);
  console.log("Time:" + moment().format());
  console.groupEnd();
  const result = next(action);
  return result;
};

// addMiddleware(loggingMiddleware);

//addMiddleware(epicMiddleware);

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;

export default store;
