import { useSelector } from 'react-redux';
import { LoadingState } from '../lib/company/companyTypes';
import { RootState } from '../reducers/rootReducer';

const useShowSpinner = () => {
  const { issuesFetching, companyFetching, weekFetching } = useSelector(
    (state: RootState) => ({
      companyFetching: state.company.sitesLoadingState === LoadingState.LOADING,
      issuesFetching: state.issues.issueCacheStatus === LoadingState.LOADING,
      weekFetching: state.calendar.fetching,
    }),
  );

  return !!(companyFetching || issuesFetching || weekFetching);
};

export default useShowSpinner;
