import useEventListener from "./useEventListener";
import { useState, useCallback, useLayoutEffect } from "react";

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handler = useCallback(() => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  }, [setDimensions]);

  useEventListener("resize", handler);

  useLayoutEffect(() => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  }, [setDimensions]);

  return dimensions;
};

export default useWindowDimensions;
