import React, { MouseEventHandler } from "react";

interface ButtonProps {
  text: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const Button: React.FC<ButtonProps> = ({ text, disabled = false, onClick }) => {
  return (
    <button className="button" disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
