import { combineLatest, of } from 'rxjs';
import { filter, switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { loadWeeklyIssues, weeklyIssuesLoaded, loadCalendarEntries, calendarEntriesLoaded } from './calendarSlice';
import { combineEpics } from 'redux-observable';
import { companyLoaded, sitesFetched } from '../company/companySlice';
import { collectionData } from 'rxfire/firestore';
import {
  getAllCalendarEntriesForRecentDays,
  convertFirebaseTimestampToDateString,
  loadCalendarCache,
} from './calendarActions';

const loadWeeklyIssuesEpic = (action$, state$) =>
  action$.pipe(
    filter(loadWeeklyIssues.match),
    switchMap(() => {
      return combineLatest(
        state$.value.company.allSiteReferences.map((siteReference) =>
          collectionData(
            getAllCalendarEntriesForRecentDays({
              siteId: siteReference,
            }),
          ).pipe(map(convertFirebaseTimestampToDateString)),
        ),
      );
    }),
    map(weeklyIssuesLoaded),
    catchError((error) => {
      console.log(error);
      return of({ type: 'error' });
    }),
  );

const loadCalendarEntriesEpic = (action$, state$) =>
  action$.pipe(
    filter(loadCalendarEntries.match),
    switchMap(() => {
      return combineLatest(
        state$.value.company.allSiteReferences.map((siteReference) =>
          collectionData(
            loadCalendarCache({ siteId: siteReference }),
          ).pipe(map(convertFirebaseTimestampToDateString)),
        ),
      );
    }),
    map(calendarEntriesLoaded),
    catchError((error) => {
      console.log(error);
      return of({ type: 'error' });
    }),
  );

const companyLoadedEpic = (action$) =>
  action$.pipe(
    filter(companyLoaded.match),
    mergeMap(() => [loadWeeklyIssues(), loadCalendarEntries()]),
    catchError((error) => {
      console.log(error);
      return of({ type: 'error' });
    }),
  );

const sitesFetchedEpic = (action$) =>
  action$.pipe(
    filter(sitesFetched.match),
    mergeMap(() => [loadWeeklyIssues(), loadCalendarEntries()]),
    catchError((error) => {
      console.log(error);
      return of({ type: 'error' });
    }),
  );

const calendarEpics = combineEpics(
  loadWeeklyIssuesEpic,
  loadCalendarEntriesEpic,
  companyLoadedEpic,
  sitesFetchedEpic,
);

export default calendarEpics;
