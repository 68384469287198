import React from "react";
import { ReactComponent as FailIcon } from "../assets/hubl-assets/dashboard-fails.svg";
// import { ReactComponent as SuccessIcon } from "../assets/hubl-assets/dashboard-issues.svg";
import { ReactComponent as SuccessIcon } from "../assets/hubl-assets/green-tick.svg";

export enum Status {
  SUCCESS = "success",
  DANGER = "danger"
}

interface StatusBoxProps {
  issueCount?: number;
}

const StatusBox: React.FC<StatusBoxProps> = ({ issueCount = 0 }) => {
  const status: Status = issueCount > 0 ? Status.DANGER : Status.SUCCESS;
  const IconComponent = {
    [Status.SUCCESS]: SuccessIcon,
    [Status.DANGER]: FailIcon
  }[status];

  const title = {
    [Status.SUCCESS]: "No issues! Great work!",
    [Status.DANGER]: `${issueCount} Issues need attention!`
  }[status];

  return (
    <div className={`status-box status-${status}`}>
      <div className="status-box__icon">
        <IconComponent />
      </div>
      <div className="status-box__title">{title}</div>
    </div>
  );
};

export default StatusBox;
