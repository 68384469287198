import { combineLatest, of, from } from "rxjs";
import { collectionData } from "rxfire/firestore";
import { filter, map, switchMap, catchError, mergeMap } from "rxjs/operators";
import { combineEpics } from "redux-observable";
import {
  loadCompanyByEmail,
  loadRegionsByCompany,
  loadSiteByReference,
  loadRegionByEmail,
  loadCompanyByReference,
  loadSiteByEmail,
  loadRegionBySiteReference,
  loadCompanies,
  loadChecksBySiteReference,
} from "../firebase/company";
import {
  loadCompany,
  companyLoaded,
  loadSites,
  sitesLoaded,
  loadRegion,
  loadSite,
  companyLoadError,
  loadSiteForSiteManagerEmail,
  sitesFetched,
  loadAllCompanies,
  allCompaniesLoaded,
} from "./companySlice";
import { Roles, setRole } from "../login/loginSlice";

const loadSiteForSiteManagerEmailEpic = (action$) =>
  action$.pipe(
    filter(loadSiteForSiteManagerEmail.match),
    switchMap(({ payload: email }) => {
      console.log("siteF");
      return from(loadSiteByEmail(email).get()).pipe(
        switchMap((sites) => {
          const siteList = [];
          sites.forEach((site) => {
            siteList.push([{ siteReference: site.id }, { ...site.data(), reference: site.id }]);
          });

          return of({ siteList, email });
        })
      );
    }),
    mergeMap(({ siteList, email }) => {
      console.log('siteList',siteList.length);
      if (siteList.length === 0) {
        console.log('start load company site');
        return of(loadCompany(email));
      }
      return [sitesLoaded(siteList), sitesFetched(), setRole(Roles.SiteManager)];
    }),
    catchError((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      return of({ type: "error" });
    })
  );

const loadCompanyByRegion = (email, siteReference) => {
  return switchMap((regions) => {
    if (siteReference) {
      regions[0].siteReferences = [siteReference];
    }

    if (regions.length > 0) {
      return from(loadCompanyByReference(regions[0].companyReference).get()).pipe(
        map((company) => ({ company: company.data(), regions })),
        map(companyLoaded)
      );
    }
    return of(loadSite(email));
  });
};

const loadCompanyEpic = (action$) =>
  action$.pipe(
    filter(loadCompany.match),
    switchMap(({ payload }) => {
      const email = payload;
      console.log('load company by email', email);
      return collectionData(loadCompanyByEmail(email), { idField: "companyReference" }).pipe(
        switchMap((companies) => {
          console.log("loaded company", companies);
          if (companies.length > 0) {
            return collectionData(loadRegionsByCompany(companies[0].companyReference)).pipe(
              map((regions) => ({ company: companies[0], regions })),
              mergeMap((company) => [companyLoaded(company), setRole(Roles.CompanyManager)])
            );
          }
          return of(loadRegion(email));
        })
      );
    }),
    catchError((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      return of({ type: "error" });
    })
  );

const loadAllCompaniesEpic = (action$, state) =>
  action$.pipe(
    filter(loadAllCompanies.match),
    switchMap(() => {
      return collectionData(loadCompanies(), "companyReference");
    }),
    map(allCompaniesLoaded),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const loadRegionEpic = (action$) =>
  action$.pipe(
    filter(loadRegion.match),
    switchMap(({ payload }) => {
      const email = payload;
      return collectionData(loadRegionByEmail(email), "regionReference").pipe(
        loadCompanyByRegion(email, null),
        mergeMap((result) => [result, setRole(Roles.RegionManager)])
      );
    }),
    catchError((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      return of({ type: "error" });
    })
  );

const loadSiteEpic = (action$) =>
  action$.pipe(
    filter(loadSite.match),
    switchMap(({ payload }) => {
      const email = payload;
      return collectionData(loadSiteByEmail(email), "siteReference").pipe(
        switchMap((sites) => {
          if (sites.length > 0) {
            return collectionData(loadRegionBySiteReference(sites[0].siteReference), "regionReference").pipe(
              loadCompanyByRegion(email, sites[0].siteReference)
            );
          }
          return of(companyLoadError);
        })
      );
    }),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const companyLoadedEpic = (action$) =>
  action$.pipe(
    filter(companyLoaded.match),
    map(loadSites),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const loadSitesEpic = (action$, state$) =>
  action$.pipe(
    filter(loadSites.match),
    switchMap(() => {
      return combineLatest(
        state$.value.company.allSiteReferences.map((siteReference) =>
          combineLatest([
            of({ siteReference }),
            from(loadSiteByReference(siteReference).get()).pipe(
              map((site) => {
                return {
                  ...site.data(),
                  reference: siteReference,
                };
              })
            ),
          ])
        )
      );
    }),
    map(sitesLoaded),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const companyEpics = combineEpics(
  loadSiteForSiteManagerEmailEpic,
  loadCompanyEpic,
  loadSitesEpic,
  companyLoadedEpic,
  loadRegionEpic,
  loadSiteEpic,
  loadAllCompaniesEpic
);

export default companyEpics;
