import React, { useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { RootState } from "../reducers/rootReducer";
import Select from "./Select";
import { setFilter, setRegionList, setLocationList, setLocation, setRegion } from "../lib/filter/filterSlice";
import { LoadingState } from "../lib/company/companyTypes";

const mapRegionsFromSite = (region: any, index: number) => ({
  label: region.regionName,
  value: index.toString(),
});

const LocationAndRegionFilter: React.FC = () => {
  const dispatch = useDispatch();

  const { regions, allSiteReferences, namesBySite, fetched } = useSelector((state: RootState) => ({
    ...state.company,
    fetched: state.company.sitesLoadingState === LoadingState.FETCHED,
  }));

  const {
    region,
    regionList: regionOptions,
    locationList: locationOptions,
    selectedLocationOption: defaultLocation,
    selectedRegionOption: defaultRegion,
  } = useSelector((state: RootState) => state.filter);

  const mapLocationsFromSite = useCallback(
    (site: string) => ({
      label: namesBySite[site],
      value: site,
    }),
    [namesBySite]
  );

  const filteredSites = useMemo(() => {
    if (region === null) {
      return allSiteReferences;
    }

    return regions[region].siteReferences;
  }, [regions, region, allSiteReferences]);

  useEffect(() => {
    batch(() => {
      dispatch(setLocationList(filteredSites.map(mapLocationsFromSite)));
      dispatch(setRegionList(Object.values(regions).map(mapRegionsFromSite)));
    });
  }, [filteredSites, dispatch, regions, mapLocationsFromSite]);

  const handleSelectLocation = useCallback(
    ({ value, label }: { value: any; label: any }) => {
      batch(() => {
        dispatch(setFilter({ location: value }));
        dispatch(setLocation({ value, label }));
      });
    },
    [dispatch]
  );

  const handleSelectRegion = useCallback(
    ({ value, label }: { value: any; label: any }) => {
      batch(() => {
        dispatch(setFilter({ region: value, location: null }));
        dispatch(setRegion({ value, label }));
        dispatch(setLocation({ value: null, label: "All" }));
      });
    },
    [dispatch]
  );

  const isSingleSite = allSiteReferences.length < 2 || !fetched;

  if (isSingleSite) {
    return null;
  }

  return (
    <>
      <Select onChange={handleSelectRegion} placeholder="Region" options={regionOptions} disabled={isSingleSite} defaultValue={defaultRegion} />
      <Select
        key={`${region}`}
        onChange={handleSelectLocation}
        placeholder="Location"
        options={locationOptions}
        disabled={isSingleSite}
        defaultValue={defaultLocation}
      />
    </>
  );
};

export default LocationAndRegionFilter;
