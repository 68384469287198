/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetched: false,
  list: [],
};

const migrationsSlice = createSlice({
  name: 'migrations',
  initialState,
  reducers: {
    loadAvailableMigrations() {},
    availableMigrationsLoaded(state: any, { payload }: any) {
      state.fetched = true;
      state.list = payload;
    },
    noop() {},
  },
});

export const {
  availableMigrationsLoaded,
  loadAvailableMigrations,
  noop,
} = migrationsSlice.actions;

export default migrationsSlice.reducer;
