import { useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';
import { useMemo } from 'react';

const useFilteredSites = () => {
  const {
    locationList,
    selectedLocation,
    selectedRegion,
    allSiteReferences,
  } = useSelector((state: RootState) => {
    return {
      allSiteReferences: state.company.allSiteReferences,
      selectedLocation: state.filter.location,
      selectedRegion: state.filter.region,
      locationList: state.filter.locationList,
    };
  });

  return useMemo(() => {
    if (selectedLocation !== null) {
      return [selectedLocation];
    }

    if (selectedRegion !== null) {
      return locationList.map(({ value }: any) => value);
    }

    return allSiteReferences;
  }, [allSiteReferences, locationList, selectedRegion, selectedLocation]);
};

export default useFilteredSites;
