import React, { PropsWithChildren, ReactNode } from "react";
import Spinner from "./Spinner";

interface BoxProps extends PropsWithChildren<{}> {
  header?: ReactNode;
  loading?: boolean;
  heigthMin?: boolean;
}

const Box: React.FC<BoxProps> = ({ children, heigthMin, header, loading = false }) => {
  return (
    <div
      className="box"
      style={{
        height: heigthMin ? "min-content" : undefined,
      }}
    >
      {header && <div className="box__header">{header}</div>}
      {loading ? (
        <div className="box__body text-center pd-tb-50">
          <Spinner />
        </div>
      ) : (
        <div className="box__body">{children}</div>
      )}
    </div>
  );
};

export default Box;
