import { combineReducers, createAction } from '@reduxjs/toolkit';
import companyReducer from '../lib/company/companySlice';
import issuesReducer from '../lib/issues/issuesSlice';
import loginReducer from '../lib/login/loginSlice';
import filterReducer from '../lib/filter/filterSlice';
import calendarReducer from '../lib/calendar/calendarSlice';
import migrationReducer from '../lib/migrations/migrationsSlice';

export const initializeAction = createAction('app/initialize');

const rootReducer = combineReducers({
  issues: issuesReducer,
  company: companyReducer,
  login: loginReducer,
  filter: filterReducer,
  calendar: calendarReducer,
  migration: migrationReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
