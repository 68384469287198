import React, { ReactNode } from "react";
import image from "../assets/hubl-assets/logo-assist.svg";
import { Link } from "react-router-dom";
import c from "classnames";
import { ReactComponent as HublIcon } from "../assets/hubl-assets/hubl-logo.svg";
import useHasAccessToProductUpload from "../hooks/useHasAccessToProductUpload";
import useHasAccessToEditChecklists from "../hooks/useHasAccessToEditChecklists";
import useHasAccessToEditNotificationSettings from "../hooks/useHasAccessToEditNotificationSettings";

const NavBarLogo: React.FC<{ company: ReactNode }> = React.memo(
  ({ company }) => (
    <div className="sidebar-logo">
      <img src={image} alt="Logo" />
      <div className="company-name">{company}</div>
    </div>
  ),
  (prev, next) => prev.company === next.company
);

interface NavItemProps {
  href: string;
  title: string;
  icon: string;
  active?: boolean;
}

const NavItem: React.FC<NavItemProps> = React.memo(
  ({ href, title, icon, active = false }) => (
    <li className={c({ active })}>
      <HublIcon />
      <Link to={href}>{title}</Link>
    </li>
  ),
  (prev, next) => prev.active === next.active
);

const Sidebar: React.FC<{ show: boolean; company: ReactNode }> = ({ show, company }) => {
  const hasAccessToProductUpload = useHasAccessToProductUpload();
  const hasAccessToEditChecklists = useHasAccessToEditChecklists();
  const hasAccessToEditNotificationSettings = useHasAccessToEditNotificationSettings();
  return (
    <aside className={c(["hubl-sidebar-wrapper", { hidden: !show }])}>
      <nav>
        <NavBarLogo company={company} />
        <ul className="sidebar-item">
          <NavItem title="Home" href="/" icon="check-circle" active={window.location.pathname === "/"} />
          {hasAccessToProductUpload && (
            <NavItem title="Product upload" href="/product-upload" icon="cloud-upload" active={window.location.pathname === "/product-upload"} />
          )}
          {hasAccessToEditChecklists && (
            <NavItem
              title="Checklist editor"
              href="/checklist-editor"
              icon="cloud-upload"
              active={window.location.pathname === "/checklist-editor"}
            />
          )}
          {hasAccessToEditNotificationSettings && (
            <NavItem
              title="Notification Settings"
              href="/notification-settings"
              icon="cloud-upload"
              active={window.location.pathname === "/notification-settings"}
            />
          )}
        </ul>
      </nav>
      <div style={{ display: "none" }} className="separator-container">
        <div className="separator" />
      </div>
      <nav style={{ display: "none" }}>
        <ul className="sidebar-item">
          <NavItem title="SAQ" href="/" icon="link" />
          <NavItem title="Drive" href="/" icon="cloud-upload" />
          <NavItem title="AssistMe" href="/" icon="headphones" />
          <NavItem title="Train" href="/" icon="graduation-cap" />
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
