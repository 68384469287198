import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type OptionType = { label: string; value: string | null };
interface FilterState {
  region: number | null;
  location: string | null;
  issueType: string | null;
  regionList: OptionType[];
  locationList: OptionType[];
  selectedRegionOption: OptionType;
  selectedLocationOption: OptionType;
}
export const initialState: FilterState = {
  region: null,
  location: null,
  issueType: null,
  locationList: [],
  regionList: [],
  selectedRegionOption: { label: 'All', value: null },
  selectedLocationOption: { label: 'All', value: null },
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, { payload }: PayloadAction<Partial<FilterState>>) => {
      Object.keys(payload).forEach((key: any) => {
        // @ts-ignore
        state[key] = payload[key];
      });
    },
    setLocationList: (state, { payload }: PayloadAction<any>) => {
      state.locationList = payload;
    },
    setRegionList: (state, { payload }: PayloadAction<any>) => {
      state.regionList = payload;
    },
    setLocation: (state, { payload }: PayloadAction<OptionType>) => {
      state.selectedLocationOption = payload;
    },
    setRegion: (state, { payload }: PayloadAction<OptionType>) => {
      state.selectedRegionOption = payload;
    },
  },
});

export const {
  setRegion,
  setLocation,
  setFilter,
  setLocationList,
  setRegionList,
} = filterSlice.actions;

export default filterSlice.reducer;
