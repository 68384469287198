import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { functions } from "../../firebase";
import { RootState } from "../../reducers/rootReducer";

const ResolveIssues: React.FC = () => {
  const [siteId, setSiteId] = useState<string>();
  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { locationList: sites } = useSelector((state: RootState) => state.filter);

  const handleSelectSite = useCallback(({ value }: { value: any }) => {
    setSiteId(value);
  }, []);

  const handleSelectFromDate = useCallback(({ target }: { target: any }) => {
    setFromDate(target.value);
  }, []);

  const handleSelectToDate = useCallback(({ target }: { target: any }) => {
    setToDate(target.value);
  }, []);

  const preventTabClose = (event: any) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const handleResolveIssues = useCallback(async () => {
    if (!siteId || !fromDate || !toDate) return;
    setLoading(true);
    window.addEventListener("beforeunload", preventTabClose);
    try {
      await functions.httpsCallable("resolveAllIssues", { timeout: 540000 })({
        siteId,
        fromDate,
        toDate,
      });
      await functions.httpsCallable("allIssuesCache", { timeout: 1080000 })({
        siteId,
      });
      await functions.httpsCallable("calendarCache", { timeout: 540000 })({
        siteId,
        fromDate,
        toDate,
      });
      alert(`Function executed for site id ${siteId}`);
    } catch (error) {
      alert(error);
    }
    window.removeEventListener("beforeunload", preventTabClose);
    setLoading(false);
  }, [fromDate, siteId, toDate]);

  return (
    <div style={{ width: "100%", padding: 20 }}>
      <div>
        <label>Site:</label>
        <Select placeholder="Site" options={sites} withAll={false} disabled={loading} onChange={handleSelectSite} />
      </div>
      <div className="mg-top-20">
        <label htmlFor="fromDate">From Date:</label>
        <input type="date" name="fromDate" id="fromDate" className="hubl-datepicker" disabled={loading} onChange={handleSelectFromDate} />
      </div>
      <div className="mg-top-20">
        <label htmlFor="toDate">To Date:</label>
        <input type="date" name="toDate" id="toDate" className="hubl-datepicker" disabled={loading} onChange={handleSelectToDate} />
      </div>
      <div className="mg-top-20">
        <Button text={loading ? "Waiting..." : "Resolve Issues"} disabled={loading} onClick={handleResolveIssues} />
      </div>
    </div>
  );
};

export default ResolveIssues;
