import React, { useState } from "react";
import { BsGripVertical } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { ChecksData } from "./checklist-data";
import useHasAccessToEditDescription from "../../hooks/useHasAccessToEditDescription";

interface ChecklistTileProps {
  check: ChecksData;
  onChange: (data: ChecksData) => void;
  onDelete: (index: number) => void;
  openModal: () => void;
}

const EditableChecklistTile = ({ check, onChange, onDelete, openModal }: ChecklistTileProps) => { 

  return (
    <>
      <td>
        <span>{check.index + 1}</span>
        <BsGripVertical
          style={{
            width: 30,
            height: 30,
            marginLeft: 5,
          }}
        />
      </td>
      <td>
        <input
          placeholder="Check name"
          value={check.name ?? ""}
          defaultValue={check.name ?? ""}
          onChange={(data) => {
            onChange({ ...check, name: data.currentTarget.value.replace(/;/g, "") });
          }}
        />
      </td>
      <td>
        <input
          value={"Description"}
          defaultValue={"Description"}
          placeholder="Description"
          onClick={(data) => {
            openModal();
          }}
        />
      </td>
      <td>
        <input
          placeholder="Help text"
          value={check.helpText ?? ""}
          defaultValue={check.helpText ?? ""}
          onChange={(data) => {
            onChange({ ...check, helpText: data.currentTarget.value.replace(/;/g, "") });
          }}
        />
      </td>{" "}
      <td>
        {" "}
        <FaTrash onClick={() => onDelete(check.index)} />
      </td>
    </>
  );
};

export default EditableChecklistTile;
