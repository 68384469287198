export interface SiteManager {
  email: string;
  name: string;
  userReference: string;
}

export interface Site {
  reference: string;
  chillDown: boolean;
  coldHolding: boolean;
  companyName: string;
  cooking: boolean;
  hotHolding: boolean;
  maintenance: boolean;
  migrations: string[];
  siteManager: SiteManager;
  sousVide: boolean;
  temperature: string;
  timeZone: string;
}

export interface Region {
  regionName: string;
  siteReferences: string[];
}

export interface Company {
  companyManagerEmail: string | null;
  companyName: string | null;
  userReference: string | null;
  companyReference: string | null;
  allergens: boolean | null;
}

export interface Checklist {
  id: string;
  list: string;
  recurrenceType: string;
  reference:string;
  siteReference: Site;
}

export type NamesBySite = { [key: string]: string };
export type TimezonesBySite = { [key: string]: string };

export enum LoadingState {
  IDLE = "idle",
  LOADING = "loading",
  ERROR = "error",
  FETCHED = "fetched",
}

export interface CompanyState {
  sitesLoadingState: LoadingState;
  companiesLoadingState: LoadingState; 
  company: Company | null;
  regions: Region[];
  allSiteReferences: string[];
  namesBySite: NamesBySite;
  timezonesBySite: TimezonesBySite;
  sites: Site[]; 
  companies: Company[];
}

export interface SiteReference {
  siteReference: string;
}
