import { createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

export interface Day {
  items: number;
  failed: number;
  resolved: number;
}

export type Days = { [key: string]: Day };
export type DaysBySite = { [key: string]: Days };

export interface CalendarState {
  fetching: boolean | false;
  fetched: boolean | false;
  weeklyIssues: DaysBySite;
  calendarEntries: Day[];
}

const initialState: CalendarState = {
  weeklyIssues: {},
  calendarEntries: [],
  fetched: false,
  fetching: true,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    loadWeeklyIssues: (state) => {
      state.fetched = false;
      state.fetching = true;
    },
    weeklyIssuesLoaded: (state, { payload }) => {
      const weeklyIssues: any = {};

      payload.forEach((calendarArray: Array<any>) => {
        if (calendarArray.length > 0) {
          const { siteId } = calendarArray[0];
          weeklyIssues[siteId] = sortBy(calendarArray, "timestamp");
        }
      });
      state.fetched = true;
      state.fetching = false;
      state.weeklyIssues = weeklyIssues;
    },
    loadCalendarEntries: (state) => {
      state.fetched = false;
      state.fetching = true;
    },
    calendarEntriesLoaded: (state, { payload }) => {
      state.fetched = true;
      state.fetching = false;
      state.calendarEntries = payload.flat();
    },
  },
});

export const { loadWeeklyIssues, weeklyIssuesLoaded, loadCalendarEntries, calendarEntriesLoaded } = calendarSlice.actions;

export default calendarSlice.reducer;
