import React from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconType } from '../../components/Icon';

interface IssueBreakDownTableRow {
  name: string;
  count: number;
  iconType: IconType;
}

const IssueBreakDownTableRow: React.FC<IssueBreakDownTableRow> = ({
  name,
  count,
  iconType,
}) => {
  return (
    <tr>
      <td>
        <Icon status={count === 0 ? 'success' : 'failed'} type={iconType} />
      </td>
      <td>{name}</td>
      <td>{`${count} issues`}</td>
      <td>
        <Link to={`/checklist/${name}`} className="btn-primary">
          View
        </Link>
      </td>
    </tr>
  );
};

interface IssueBreakDownTableProps {
  issues?: any;
}
const IssueBreakDownTable: React.FC<IssueBreakDownTableProps> = ({
  issues = {},
}) => {
  return (
    <table className="table table-bordered issue-breakdown-table">
      <tbody>
        {Object.keys(issues).map((label: string) => {
          return (
            <IssueBreakDownTableRow
              key={label}
              iconType={label as IconType}
              count={issues[label]}
              name={label}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default IssueBreakDownTable;
