import useSelectedSiteReferences from '../../hooks/useSelectedSiteReferences';
import {
  getChecklistTableRow,
  getDeliveryTableRow,
  getTemperatureTableRow,
  getTimeoutsTableRow,
} from './utils';
import { useMemo } from 'react';
import { RootState } from '../../reducers/rootReducer';
import { useSelector } from 'react-redux';

const rowFormatters = {
  Checklists: getChecklistTableRow,
  Deliveries: getDeliveryTableRow,
  'Hot temperatures': getTemperatureTableRow,
  'Cold temperatures': getTemperatureTableRow,
  'Time outs': getTimeoutsTableRow,
};

const useSelectedIssues = () => {
  const { issuesBySite, selectedIssueType, namesBySite, timezonesBySite } = useSelector(
    (state: RootState) => ({
      selectedIssueType: state.filter.issueType,
      namesBySite: state.company.namesBySite,
      issuesBySite: state.issues.issueCacheExtended,
      timezonesBySite: state.company.timezonesBySite,
    }),
  );

  const selectedLocations = useSelectedSiteReferences();

  return useMemo(() => {
    if (selectedLocations.length === 0) {
      return [];
    }

    return selectedLocations
      .map((siteReference) => {
        if (!siteReference) {
          return [];
        }

        if (!issuesBySite[siteReference]) {
          return [];
        }

        const issuesToShow: any[] = [];

        Object.entries(rowFormatters).forEach(([issueType, formatRow]) => {
          return (issuesBySite as any)[siteReference][issueType.toLowerCase()].forEach(
            (issue: any) => {
              if (['All', issueType].includes(selectedIssueType as string)) {
                issuesToShow.push(formatRow(namesBySite[siteReference], issue, timezonesBySite[siteReference]));
              }
            },
          );
        });

        return issuesToShow;
      })
      .flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocations, issuesBySite, selectedIssueType]);
};

export default useSelectedIssues;
