import React from 'react';
import { Site } from '../../lib/company/companyTypes';

interface MigrationListProps {
  sites: Site[];
}

const MigrationList: React.FC<MigrationListProps> = ({ sites }) => {
  return (
    <div>
      <ul className="list-group">      
        {sites.map((site: Site) => (
          <li className="list-group-item">{site.companyName}</li>
        ))}
      </ul>
    </div>
  );
};

export default MigrationList;
