import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let firebaseConfig = {
  apiKey: "AIzaSyAqs19J9aOvB7k838R_TMkHtPmp6EY4dvQ",
  authDomain: "hubl-bd876.firebaseapp.com",
  projectId: "hubl-bd876",
  storageBucket: "hubl-bd876.appspot.com",
  messagingSenderId: "884076706037",
  appId: "1:884076706037:web:dc4b4019346429de326dbc",
  measurementId: "G-C3YR06ZPF4",
};

if (process.env.REACT_APP_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyCO7J-HANUXkEgx2PopGQzQs-xQB6XLq78",
    authDomain: "hubl-496d2.firebaseapp.com",
    projectId: "hubl-496d2",
    storageBucket: "hubl-496d2.appspot.com",
    messagingSenderId: "928750546371",
    appId: "1:928750546371:web:7724e3581493381f6be176",
    measurementId: "G-3V250PRBG1",
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const functions = firebase.app().functions("europe-west1");

export default firebase;
