import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import {
  CompanyState,
  LoadingState,
  Region,
  Site,
  SiteReference,
} from './companyTypes';

export const initialState: CompanyState = {
  sitesLoadingState: LoadingState.IDLE,
  companiesLoadingState: LoadingState.IDLE, 
  company: {
    allergens: null,
    companyManagerEmail: null,
    companyName: null,
    userReference: null,
    companyReference: null,
  },
  regions: [],
  allSiteReferences: [],
  namesBySite: {},
  timezonesBySite: {},
  sites: [], 
  companies: [],
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    loadSiteForSiteManagerEmail: (state, { payload }) => {
      console.log('load');
    },
    loadCompany: (state, { payload }: PayloadAction<string>) => { 
      state.sitesLoadingState = LoadingState.LOADING;
    },
    companyLoaded: (state, { payload }: PayloadAction<any>) => {
      state.sitesLoadingState = LoadingState.FETCHED;

      state.company = payload.company;
      state.regions = payload.regions;
      state.allSiteReferences = uniq(
        payload.regions.map((region: Region) => region.siteReferences).flat(),
      );
      console.log('loaded company', payload);
    },
    loadSites: () => {},
    sitesFetched: (state) => {
      console.log('sitesFetched', state.allSiteReferences);
      state.sitesLoadingState = LoadingState.FETCHED;
    },
    sitesLoaded: (state, { payload }: PayloadAction<any>) => {
      const allSiteReferences: string[] = [];
      payload.forEach(
        ([{ siteReference }, { companyName, timeZone }]: [SiteReference, Site]) => {
          state.namesBySite[siteReference] = companyName;
          state.timezonesBySite[siteReference] = timeZone || 'UTC+0'
          allSiteReferences.push(siteReference);
        },
      );

      console.log('allSitresReference', allSiteReferences);

      
      state.allSiteReferences = uniq(allSiteReferences);
      state.sites = payload.map(([_, site]: [SiteReference, Site]) => site); 
    },
    loadRegion: (state, { payload }: PayloadAction<string>) => {
      state.sitesLoadingState = LoadingState.LOADING;
    },
    loadSite: (state, { payload }: PayloadAction<string>) => {
      state.sitesLoadingState = LoadingState.LOADING;
    },
    companyLoadError: (state) => {
      state.sitesLoadingState = LoadingState.ERROR;
    },
    loadAllCompanies: (state) => {
      state.companiesLoadingState = LoadingState.LOADING;
    },
    allCompaniesLoaded: (state, { payload }: any) => {
      state.companiesLoadingState = LoadingState.FETCHED;
      state.companies = payload;
    },
  },
});

export const {
  sitesFetched,
  loadSiteForSiteManagerEmail,
  loadCompany,
  companyLoaded,
  loadSites,
  sitesLoaded,
  loadRegion,
  loadSite,
  companyLoadError,
  loadAllCompanies,
  allCompaniesLoaded,
} = companySlice.actions;

export default companySlice.reducer;
