import React from "react";
import c from "classnames";
import ReactSelect from "react-select";

const MED_GRAY = "#5a6175";
const DARK_GRAY = "#484d5e";
const DISABLED_GRAY = "#D4D7DA";

interface InputProps {
  isSelected: boolean;
  isDisabled: boolean;
  isFocused: boolean;
}

const getBackground = ({ isSelected, isDisabled, isFocused }: InputProps) => {
  if (isSelected) {
    return DARK_GRAY;
  }

  if (isDisabled) {
    return "white";
  }

  if (isFocused) {
    return MED_GRAY;
  }

  return "white";
};

const getFontColor = ({ isSelected, isDisabled, isFocused }: InputProps) => {
  if (isSelected) {
    return "white";
  }

  if (isDisabled) {
    return "#ccc";
  }

  if (isFocused) {
    return "white";
  }

  return MED_GRAY;
};

const styles = {
  control: (styles: any, state: any) => {
    return {
      ...styles,
      cursor: "pointer",
      backgroundColor: state.selectProps.isDisabled ? DISABLED_GRAY : "white",
      borderRadius: "5px",
      boxShadow: "1px 1px 5px 0 rgba(90,97,117,0.5)",
      outline: "none !important",
      border: 0,
      width: "auto",
      minWidth: 300,
      height: 30,

      "&:hover": {
        backgroundColor: "#F6F6F6",
      },

      "&:active, &:focus": {
        backgroundColor: "#e3e3e3",
      },
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      width: "auto",
      minWidth: 300,
      marginTop: 0,
      border: 0,
      boxShadow: "1px 1px 5px 0 rgba(90,97,117,0.5)",
    };
  },
  option: (styles: { [x: string]: any }, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      cursor: isDisabled ? "not-allowed" : "default",
      fontFamily: "Rubik",
      backgroundColor: getBackground({ isFocused, isSelected, isDisabled }),
      color: getFontColor({ isFocused, isSelected, isDisabled }),
    };
  },
  input: (styles: any, data: any) => {
    return {
      ...styles,
      height: 30,
    };
  },
};

interface SelectProps {
  options?: any[];
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
  withAll?: boolean;
  searchable?: boolean;
  onChange?: (value: any, action: any) => void;
}

const SingleValue = React.memo(({ children, selectProps: { placeholder } }: any) => {
  return <div className="hubl-select-single-value">{`${placeholder} - ${children}`}</div>;
});

const Select: React.FC<SelectProps> = React.memo(
  ({ onChange, placeholder = "Select...", disabled = false, searchable = false, options = [], withAll = true, ...rest }) => {
    const optionsWithAll = [...(withAll ? [{ label: "All", value: null }] : []), ...options];

    return (
      <div className={c(["hubl-select"])}>
        <ReactSelect
          placeholder={placeholder}
          onChange={onChange}
          styles={styles}
          options={optionsWithAll}
          isSearchable={searchable}
          isDisabled={disabled}
          components={{ SingleValue }}
          {...rest}
        />
      </div>
    );
  },
  (prev, next) => prev.defaultValue === next.defaultValue && prev.options === next.options && prev.disabled === next.disabled
);

export default Select;
