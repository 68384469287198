/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../company/companyTypes';

export interface Issue {
  statistics: number | 0;
  deliveries: any[];
  cookings: any[];
  hotHoldings: any[];
  sousVides: any[];
  fridges: any[];
  freezers: any[];
  coldHoldings: any[];
  chillDowns: any[];
  dailyChecklists: any[];
  weeklyChecklists: any[];
  monthlyChecklists: any[];
  toShow: any;
}

export type IssuesBySite = { [key: string]: Issue };

export type IssueCacheType =
  | 'checklists'
  | 'deliveries'
  | 'cold temperatures'
  | 'hot temperatures'
  | 'time outs';

export interface IssuesState {
  fetching: boolean | false;
  fetched: boolean | false;
  issueCache: { [key: string]: { [key in IssueCacheType]: number } };
  issueCacheExtended: { [key: string]: { [key in IssueCacheType]: any } };
  issueCacheStatus: LoadingState;
  issuesBySite: IssuesBySite;
}

const initialState: IssuesState = {
  fetching: false,
  fetched: false,
  issueCacheExtended: {},
  issueCache: {},
  issueCacheStatus: LoadingState.IDLE,
  issuesBySite: {},
};

export const COLDS: string[] = [
  'fridge',
  'chillDown',
  'freezer',
  'coldHolding',
];

export const HOTS: string[] = ['cooking', 'hotHolding', 'sousVide'];

export const CHECKLISTS: string[] = [
  'dailyChecklist',
  'weeklyChecklist',
  'monthlyChecklist',
];

export const CACHE_TYPES = {
  checklists: CHECKLISTS,
  deliveries: ['delivery'],
  'cold temperatures': COLDS,
  'hot temperatures': HOTS,
};

const getIssuesCountForResolutionTypes = (
  issues: Array<any>,
  type: string[],
) => {
  return issues.filter((issue) => type.includes(issue.resolutionType));
};

const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    loadIssueCache: (state) => {
      state.issueCacheStatus = LoadingState.LOADING;
    },
    issueCacheLoaded: (state, { payload }) => {
      console.log('issueCacheLoaded');
      const issueCache:any = {};
      const issueCacheExtended:any = {};

      const siteIssues = payload.flat();

      if (siteIssues.length > 0) {
        payload.forEach((issues: any) => {
          if (issues.length === 0) {
            return;
          }
          const [{ siteId }] = issues;
          const timeOuts = issues.filter((issue: any) => issue.timedOut);
          const nonTimeOuts = issues.filter((issue: any) => !issue.timedOut);

          issueCache[siteId] = {
            ...Object.fromEntries(
              Object.entries(CACHE_TYPES).map(([type, resolutionTypes]) => [
                type,
                getIssuesCountForResolutionTypes(nonTimeOuts, resolutionTypes)
                  .length,
              ]),
            ),
            'time outs': timeOuts.length,
          };

          issueCacheExtended[siteId] = {
            ...Object.fromEntries(
              Object.entries(CACHE_TYPES).map(([type, resolutionTypes]) => [
                type,
                getIssuesCountForResolutionTypes(nonTimeOuts, resolutionTypes),
              ]),
            ),
            'time outs': timeOuts,
          };
        });
        // const [{ siteId }] = siteIssues;

        // console.log('siteId', siteId);

        // const timeOuts = siteIssues.filter((issue: any) => issue.timedOut);
        // const nonTimeOuts = siteIssues.filter((issue: any) => !issue.timedOut);

        // issueCache[siteId] = {
        //   ...Object.fromEntries(
        //     Object.entries(CACHE_TYPES).map(([type, resolutionTypes]) => [
        //       type,
        //       getIssuesCountForResolutionTypes(nonTimeOuts, resolutionTypes)
        //         .length,
        //     ]),
        //   ),
        //   'time outs': timeOuts.length,
        // };

        // issueCacheExtended[siteId] = {
        //   ...Object.fromEntries(
        //     Object.entries(CACHE_TYPES).map(([type, resolutionTypes]) => [
        //       type,
        //       getIssuesCountForResolutionTypes(nonTimeOuts, resolutionTypes),
        //     ]),
        //   ),
        //   'time outs': timeOuts,
        // };
      }

      state.issueCache = issueCache;
      state.issueCacheExtended = issueCacheExtended;
      state.issueCacheStatus = LoadingState.FETCHED;
    },
  },
});

export const { loadIssueCache, issueCacheLoaded } = issuesSlice.actions;

export default issuesSlice.reducer;
