import { Location } from "history";
import React, { useEffect, useState } from "react";

import Modal from "./Modal";
import Button from "./Button";
import { Prompt } from "react-router-dom";

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    console.log("nextLocation", nextLocation.state);

    if (shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      handleConfirmNavigationClick();
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}

      <Modal visible={modalVisible} onClose={function (): void {}} title="Are you sure leave this page?" autoHeight>
        <div className="row g-1 px-3">
          <Button onClick={closeModal} text={"Cancel"} />
          <span
            style={{
              width: 10,
            }}
          ></span>
          <Button onClick={handleConfirmNavigationClick} text={"Leave"} />
        </div>
      </Modal>
    </>
  );
};
export default RouteLeavingGuard;
