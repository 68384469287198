import { nanoid } from "nanoid";
import Papa from "papaparse";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import Container from "../../components/Container";
import Row from "../../components/Row";
import { storageRef } from "../../firebase";
import useHasAccessToProductUpload from "../../hooks/useHasAccessToProductUpload";
import { RootState } from "../../reducers/rootReducer";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ALLERGENS = [
  "sulphites",
  "celery",
  "nuts",
  "peanuts",
  "milk",
  "crustaceans",
  "molluscs",
  "egg",
  "lupin",
  "fish",
  "gluten",
  "sesame",
  "soya",
  "mustard",
  "vegan",
  "vegetarian",
];

const isAllergensValid = (allergens: string[]): boolean => {
  if (!allergens || !allergens.length) {
    return false;
  }
  return allergens.every((allergen) => ALLERGENS.includes(allergen));
};

interface RowValidationResult {
  isValid: boolean;
  errors?: string[];
}

const useValidateCsvRow = () => {
  const { allSiteReferences } = useSelector((state: RootState) => state.company);
  return useCallback(
    (row: any): RowValidationResult => {
      const errors = [];

      if (!allSiteReferences.includes(row[0][0])) {
        errors.push(`Site with id ${row[0]} is not available within your company.`);
      }
      const allergens = row[0].slice(3);

      if (!isAllergensValid(allergens)) {
        errors.push(`Allergens are missing or unkown allergen in the following list: ${allergens.join(", ")}.`);
      }

      if (errors.length) {
        return {
          isValid: false,
          errors,
        };
      }
      return { isValid: true };
    },
    [allSiteReferences]
  );
};

const useUploadFile = (onComplete: () => void, onError: (error: Error) => void) => {
  const [progress, setProgress] = useState(0);

  const upload = useCallback(
    (filePath: string, file: File) => {
      const uploadTask = storageRef.child(filePath).put(file);
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          var uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(uploadProgress);
        },
        onError,
        onComplete
      );
    },
    [onComplete, onError]
  );
  return {
    progress,
    upload,
  };
};

const ProductUploadScreen: React.FC = () => {
  const { role } = useSelector((state: RootState) => state.login);
  const { acceptedFiles, getInputProps, getRootProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: {
      "text/csv": [],
      "application/vnd.ms-excel": [],
      "application/csv": [],
      "text/plain": [],
    },
    multiple: false,
  });

  const [csvValidationErrors, setCsvValidationErrors] = useState<string[]>([]);
  const [selectedFileName, setSelectedFileName] = useState("");

  useEffect(() => {
    if (acceptedFiles.length) {
      setSelectedFileName(acceptedFiles[0].name);
    } else {
      setSelectedFileName("");
    }
  }, [acceptedFiles]);

  const validateRow = useValidateCsvRow();
  const { progress, upload } = useUploadFile(
    () => setSelectedFileName(""),
    (error) => console.log("Upload error", error)
  );

  const handleUpload = useCallback(async () => {
    const validationErrors: string[] = [];
    let stepped = 0;
    Papa.parse<string>(acceptedFiles[0], {
      delimiter: ";",
      skipEmptyLines: true,
      step: (row) => {
        stepped++;
        const validationResult = validateRow([row.data]);
        if (!validationResult.isValid) {
          validationErrors.push(`Line ${stepped}: ${validationResult.errors?.join(" ")}`);
        }
      },
      complete: () => {
        setCsvValidationErrors(validationErrors);
        if (validationErrors.length) {
          setSelectedFileName("");
        } else {
          upload(`allergens/allergen-products-${nanoid()}.csv`, acceptedFiles[0]);
        }
      },
    });
  }, [acceptedFiles, upload, validateRow]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const hasAccessToProductUpload = useHasAccessToProductUpload();

  if (!role) {
    return null;
  }

  if (!hasAccessToProductUpload) {
    return (
      <Container>
        <Row>
          <h1>Permission denied</h1>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <h1>Upload product CSV</h1>
      </Row>
      <Row>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag and drop your CSV file here or click to browse it from your computer</p>
          {selectedFileName && <div style={{ fontWeight: "bold" }}>{selectedFileName}</div>}
        </div>
      </Row>
      <Row>
        <button className="btn-primary m-3" disabled={!selectedFileName} onClick={handleUpload}>
          Upload
        </button>
      </Row>
      {progress > 0 && (
        <>
          <Row>
            <h4>Your file will be processed shortly after your file is uploaded</h4>
          </Row>
          <Row>
            <div className="progress" style={{ flex: 1 }}>
              <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }}>
                {progress}%
              </div>
            </div>
          </Row>
        </>
      )}
      {csvValidationErrors.length > 0 && (
        <>
          <Row>
            <h4>There are some issues with your file</h4>
          </Row>
          {csvValidationErrors.map((error, index) => (
            <Row>
              <div key={index} className="alert alert-danger" role="alert">
                {error}
              </div>
            </Row>
          ))}
        </>
      )}
      <Row>
        <p>The file should have the following syntax</p>
      </Row>
      <Row>
        <pre>site id;product id;product name;allergen 1;allergen 2</pre>
      </Row>
      <Row>
        <p>
          <ul>
            <li>You can only use site ids that are available within your company</li>
            <li>
              The products will be indentified by the <code>product id</code>
            </li>
            <li>
              You can add as many allergens as you want from the following list
              <br />
              <code>{ALLERGENS.join(", ")}</code>
            </li>
          </ul>
        </p>
      </Row>
      <Row>
        <p>Example file</p>
      </Row>
      <div>
        <pre style={{ fontFamily: "monospace" }}>
          {`mySiteId;import-1;Import 1;crustaceans;milk;vegan
mySiteId;import-2;Import 2 renamed;fish;gluten;milk
mySiteId;import-3;Import 3;fish
mySiteId;import-4;Import 4;milk
mySiteId;import-5;Import 5;fish;vegetarian`}
        </pre>
      </div>
    </Container>
  );
};

export default ProductUploadScreen;
