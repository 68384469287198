import React, { useState } from 'react';
import Container from '../components/Container';
import Row from '../components/Row';
import Box from '../components/Box';
import { Circle } from './home/SevenDaysTable';
import Select from '../components/Select';
import { range } from 'lodash';
import Icon, { iconMap, IconType } from '../components/Icon';
import StatusBox from '../components/StatusBox';
import { ReactComponent as HublIcon } from '../assets/hubl-assets/hubl-logo.svg';
import { AccentSpinner } from '../components/Spinner';
import Modal from '../components/Modal';
const style = {
  width: 100,
  minHeight: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
};

const testOptions = range(0, 10).map((value: number) => ({
  value,
  label: `Option ${value}`,
}));

const TestScreen = () => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const startLoad = () => {
    setLoaded(false);
    setTimeout(() => setLoaded(true), 4000);
  };

  return (
    <Container>
      <Row>
        <div className="col-lg-4">
          <Box header="Hubl logo small">
            <div style={{ background: '#5a6175' }} className="mg-tb-30 pd-20">
              <HublIcon />
            </div>
          </Box>
        </div>
        <div className="col-lg-4">
          <Box header="Select Test">
            <div className="mg-tb-30">
              <label className="mg-bottom-20">
                <input
                  type="checkbox"
                  checked={disabled}
                  onChange={(e) => setDisabled(e.target.checked)}
                />
                <span className="mg-left-20">Is disabled?</span>
              </label>
              <Select
                placeholder="Test"
                options={testOptions}
                disabled={disabled}
                defaultValue={{ label: 'All', value: null }}
              />
            </div>
          </Box>
        </div>
        <div className="col-lg-4">
          <Box header="Select Test">
            <div style={{ display: 'block' }}>
              {(Object.keys(iconMap) as IconType[]).map(
                (type: IconType, index: number) => (
                  <Icon status="success" type={type} />
                ),
              )}
            </div>
            <div style={{ display: 'block' }}>
              {(Object.keys(iconMap) as IconType[]).map(
                (type: IconType, index: number) => (
                  <Icon status="failed" type={type} />
                ),
              )}
            </div>
          </Box>
          <div className="mg-top-20">
            <Box header="Small loader">
              <div
                style={{ background: '#5a6175', width: '100%' }}
                className="pd-20 text-center"
              >
                <AccentSpinner />
              </div>
            </Box>
          </div>
        </div>
      </Row>
      <Row className="mg-top-30">
        <div className="col-lg-4">
          <Box header="Calendar circle dot tests">
            <div className="seven-days-table" style={style}>
              <Circle items={{ items: 0, failed: 0, resolved: 0 }} />
              <Circle items={{ items: 1, failed: 0, resolved: 0 }} />
              <Circle items={{ items: 1, failed: 1, resolved: 0 }} />
              <Circle items={{ items: 1, failed: 1, resolved: 1 }} />
            </div>
          </Box>
        </div>

        <div className="col-lg-4">
          <Box header="Always loading" loading></Box>
        </div>

        <div className="col-lg-4">
          <Box header="Loading box (with restart)" loading={!loaded}>
            <div className="pd-40 text-center">
              <input
                type="button"
                value="Restart loading"
                className="btn-primary"
                onClick={startLoad}
              />
            </div>
          </Box>

          <div className="mg-top-20">
            <Box header="Open modal">
              <div className="pd-40 text-center">
                <button
                  className="btn-primary"
                  onClick={() => setModalVisibility(true)}
                >
                  Open Modal{' '}
                </button>
              </div>
            </Box>
          </div>
        </div>
      </Row>

      <Row className="mg-top-30">
        <div className="col-lg-6">
          <Box header="Status success">
            <StatusBox issueCount={0} />
          </Box>
        </div>

        <div className="col-lg-6">
          <Box header="Status fail">
            <StatusBox issueCount={1} />
          </Box>
        </div>
      </Row>

      <Modal
        visible={modalVisibility}
        onClose={() => setModalVisibility(false)}
        title="This is the title"
      >
        This is the body of the test modal
      </Modal>
    </Container>
  );
};

export default TestScreen;
