import { combineLatest, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { loadIssueCache, issueCacheLoaded } from "./issuesSlice";
import { combineEpics } from "redux-observable";
import { companyLoaded, sitesFetched } from "../company/companySlice";
import { loadIssueEntries } from "../../lib/firebase/issues";
import { collectionData } from "rxfire/firestore";

const loadIssueCacheEpic = (action$, state) =>
  action$.pipe(
    filter(loadIssueCache.match),
    switchMap(() => {
      console.log("start load issues", state.value.company.allSiteReferences);
      return combineLatest(state.value.company.allSiteReferences.map((siteReference) => collectionData(loadIssueEntries({ siteId: siteReference }))));
    }),
    map(issueCacheLoaded),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const companyLoadedEpic = (action$) =>
  action$.pipe(
    filter(companyLoaded.match),
    map(loadIssueCache),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const sitesFetchedEpic = (action$) =>
  action$.pipe(
    filter(sitesFetched.match),
    map(loadIssueCache),
    catchError((error) => {
      console.log(error);
      return of({ type: "error" });
    })
  );

const issuesEpics = combineEpics(companyLoadedEpic, loadIssueCacheEpic, sitesFetchedEpic);

export default issuesEpics;
