import React, { useEffect, useState } from "react";
import Box from "../../components/Box";
import Input from "react-select/dist/declarations/src/components/Input";
import Button from "../../components/Button";
import { loadNotificationType, updateNotificationType } from "../../lib/firebase/notification";
import { DocumentSnapshot } from "rxfire/firestore/interfaces";

export default function NotificationTypes() {
  const [checklists, setChecklists] = useState({
    daily: { title: "", body: "" },
    weekly: { title: "", body: "" },
    monthly: { title: "", body: "" },
    temperatures: { title: "", body: "" },
  });

  useEffect(() => {
    const fetchData = async () => {
      const dailyDoc = await loadNotificationType("daily-checklist-expire").get();
      const weeklyDoc = await loadNotificationType("weekly-checklist-expire").get();
      const monthlyDoc = await loadNotificationType("monthly-checklist-expire").get();
      const temperatureDoc = await loadNotificationType("temperature-checks-alert").get();

      if (dailyDoc.exists && weeklyDoc.exists && monthlyDoc.exists) {
        setChecklists({
          daily: {
            title: (dailyDoc.data() as any).title,
            body: (dailyDoc.data() as any).body,
          },
          weekly: {
            title: (weeklyDoc.data() as any).title,
            body: (weeklyDoc.data() as any).body,
          },
          monthly: {
            title: (monthlyDoc.data() as any).title,
            body: (monthlyDoc.data() as any).body,
          },
          temperatures: {
            title: (temperatureDoc.data() as any).title,
            body: (temperatureDoc.data() as any).body,
          },
        });
      }
    };
    fetchData();
  }, []);

  const handleTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>, type: string, field: string) => {
    const updatedChecklist = { ...(checklists as any)[type], [field]: event.target.value };
    setChecklists({ ...checklists, [type]: updatedChecklist });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, type: string, field: string) => {
    const updatedChecklist = { ...(checklists as any)[type], [field]: event.target.value };
    setChecklists({ ...checklists, [type]: updatedChecklist });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateNotificationType("daily-checklist-expire", checklists.daily);
      await updateNotificationType("weekly-checklist-expire", checklists.weekly);
      await updateNotificationType("monthly-checklist-expire", checklists.monthly);
      await updateNotificationType("temperature-checks-alert", checklists.temperatures);
      alert("Checklists saved successfully!");
    } catch (error) {
      console.error("Error saving checklists: ", error);
      alert("Error saving checklists. Please try again." + JSON.stringify(error));
    }
  };
  return (
    <>
      <Box header="Types">
        <div className="container my-4 px-0 mx-0">
          <form onSubmit={handleFormSubmit} className="d-flex flex-column gap-5 row p-4">
            <div className="d-flex flex-column align-items-start">
              <span className="fw-bold fs-4 mb-4">Daily Checklist</span>
              <div
                className="d-flex flex-column gap-3  "
                style={{
                  width: "100%",
                }}
              >
                <div className="col">
                  <span>Title</span>
                  <input
                    placeholder="Title"
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    value={checklists.daily.title}
                    onChange={(e) => handleInputChange(e, "daily", "title")}
                  />
                </div>
                <div className="col">
                  <span>Body</span>
                  <textarea
                    placeholder="Body"
                    className="form-control"
                    rows={3} // Itt állíthatjuk be a sorok számát
                    value={checklists.daily.body}
                    onChange={(e) => handleTextAreaInputChange(e, "daily", "body")}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-start">
              <span className="fw-bold fs-4 mb-4">Weekly Checklist</span>
              <div
                className="d-flex flex-column gap-3  "
                style={{
                  width: "100%",
                }}
              >
                <div className="col">
                  <span>Title</span>
                  <input
                    placeholder="Title"
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    value={checklists.weekly.title}
                    onChange={(e) => handleInputChange(e, "weekly", "title")}
                  />
                </div>
                <div className="col">
                  <span>Body</span>
                  <textarea
                    placeholder="Body"
                    className="form-control"
                    rows={3} // Itt állíthatjuk be a sorok számát
                    value={checklists.weekly.body}
                    onChange={(e) => handleTextAreaInputChange(e, "weekly", "body")}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-start">
              <span className="fw-bold fs-4 mb-4">Monthly Checklist</span>
              <div
                className="d-flex flex-column gap-3  "
                style={{
                  width: "100%",
                }}
              >
                <div className="col">
                  <span>Title</span>
                  <input
                    placeholder="Title"
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    value={checklists.monthly.title}
                    onChange={(e) => handleInputChange(e, "monthly", "title")}
                  />
                </div>
                <div className="col">
                  <span>Body</span>
                  <textarea
                    placeholder="Body"
                    className="form-control"
                    rows={3}
                    value={checklists.monthly.body}
                    onChange={(e) => handleTextAreaInputChange(e, "monthly", "body")}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-column align-items-start">
              <span className="fw-bold fs-4 mb-4">Temperatures</span>
              <div
                className="d-flex flex-column gap-3  "
                style={{
                  width: "100%",
                }}
              >
                <div className="col">
                  <span>Title</span>
                  <input
                    placeholder="Title"
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    value={checklists.temperatures.title}
                    onChange={(e) => handleInputChange(e, "temperatures", "title")}
                  />
                </div>
                <div className="col">
                  <span>Body</span>
                  <textarea
                    placeholder="Body"
                    className="form-control"
                    rows={3}
                    value={checklists.temperatures.body}
                    onChange={(e) => handleTextAreaInputChange(e, "temperatures", "body")}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-end mt-auto">
              <input type="submit" className="btn btn-primary btn-xl mt-3" value="Save" />
            </div>
          </form>
        </div>
      </Box>
    </>
  );
}
