import { useMemo } from 'react';
import { pick, sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { ISSUE_GROUP_LABELS } from '../../common/issueTypes';
import useFilteredSites from '../../hooks/useFilteredSites';

const useSelectedSitesCache = () => {
  const { issueCache } = useSelector((state: RootState) => {
    return {
      issueCache: state.issues.issueCache,
    };
  });

  const filteredSiteReferences = useFilteredSites();

  return useMemo(() => {
    if (!issueCache) {
      return Object.values(ISSUE_GROUP_LABELS).map((resolutionType) => [
        resolutionType,
        0,
      ]);
    }

    const selectedSitesCaches = pick(issueCache, filteredSiteReferences);

    return Object.fromEntries(
      Object.values(ISSUE_GROUP_LABELS).map((resolutionType) => [
        resolutionType,
        sumBy(
          Object.values(selectedSitesCaches),
          resolutionType.toLocaleLowerCase(),
        ),
      ]),
    );
  }, [filteredSiteReferences, issueCache]);
};

export default useSelectedSitesCache;
