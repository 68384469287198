import { difference } from 'lodash';
import { createSelector } from 'reselect';
import { Site } from '../../lib/company/companyTypes';
import { RootState } from '../../reducers/rootReducer';

const getNonMigratedSites = createSelector(
  (state: RootState) => state.company.sites,
  (state: RootState) => state.migration.list,
  (sites, migrations) => {
    const migrationIds: string[] = migrations.map(
      ({ id }: { id: string }) => id,
    );

    const isAllMigrationRunned = (site: Site) => {
      if (!site.migrations) {
        return false;
      }
      return difference(migrationIds, site.migrations).length === 0;
    };

    return sites.filter((site: Site) => !isAllMigrationRunned(site));
  },
);

export { getNonMigratedSites };
