import { ReactComponent as CheckListIcon } from "../assets/hubl-assets/icon-checklist.svg";
import { ReactComponent as HotIcon } from "../assets/hubl-assets/icon-hot-check.svg";
import { ReactComponent as ColdIcon } from "../assets/hubl-assets/icon-cold-check.svg";
import { ReactComponent as DeliveryIcon } from "../assets/hubl-assets/icon-deliveries.svg";
import { ReactComponent as TimeoutIcon } from "../assets/hubl-assets/hourglass-resized.svg";
import c from "classnames";
import React from "react";

export const iconMap = {
  Checklists: CheckListIcon,
  Deliveries: DeliveryIcon,
  "Cold temperatures": ColdIcon,
  "Hot temperatures": HotIcon,
  "Time outs": TimeoutIcon,
};

export type IconType = keyof typeof iconMap;

const Icon: React.FC<{
  status: "success" | "failed";
  type: IconType;
}> = ({ status, type }) => {
  const IconComponent = iconMap[type];
  return (
    <>
      <div className={c(["hubl-icon", status])}>
        <IconComponent />
      </div>
    </>
  );
};

export default Icon;
