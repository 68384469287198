import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import Sidebar from './Sidebar';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../lib/login/loginSlice';
import { RootState } from '../reducers/rootReducer';
import { getUser } from '../utils/storage';
import c from 'classnames';

const Hamburger: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className="hamburger" onClick={onClick}>
      <i className="fa fa-bars"></i>
    </div>
  );
};

const UserMenu: React.FC = () => {
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(false);

  const handleLogout = useCallback(
    (event:any) => {
      event.preventDefault();
      setFocus(false);
      dispatch(logout());
    },
    [dispatch],
  );

  useLayoutEffect(() => {
    window.addEventListener('click', (event) => {
      // @ts-ignore
      var path = event.path || (event.composedPath && event.composedPath());
      if (!path.includes(document.getElementById('userMenu')) && focus) {
        setFocus(false);
      }
    });
  }, [focus]);

  return (
    <div className="user-menu" id="userMenu">
      {/* <button className="notification--button">
        <i className="fa fa-bell"></i>
      </button> */}
      <button
        className={c(['user-button', { focus }])}
        onClick={() => setFocus(!focus)}
      >
        <i className="fa fa-user"></i>
      </button>
      <div className="user-menu__logout">
        <a href="/" id="logout" title="Logout" onClick={handleLogout}>
          Logout
        </a>
      </div>
    </div>
  );
};

const Layout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  const companyName = useSelector(
    (state: RootState) => state.company.company?.companyName,
  );

  useEffect(() => {
    const user = getUser();
    console.log('userMenu: ', user);
    if (!user || (user && user.superAdmin && !user.companyEmail)) {
      window.location.href = '/login';
    }
  }, []);

  return (
    <div id="main-container">
      <Sidebar show={showSidebar} company={companyName} />
      <main>
        <header>
          <Hamburger onClick={() => setShowSidebar(!showSidebar)} />

          <UserMenu />
        </header>

        <div id="content">{children}</div>
      </main>
    </div>
  );
};

export default Layout;
