import React, { useEffect, useState } from "react";

import NotificationTypes from "./NotificationTypes";
import BulkNotifications from "./BulkNotifications";

export default function NotificationsScreen() {
  return (
    <>
      <div className="d-flex flex-row gap-5">
        <NotificationTypes />
        <BulkNotifications />
      </div>
    </>
  );
}
