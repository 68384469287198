import React, { useCallback, useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { Day, DaysBySite } from "../../lib/calendar/calendarSlice";
import c from "classnames";
import { Checklist, NamesBySite } from "../../lib/company/companyTypes";
import { range } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";
import { deleteChecklistBySiteAndChecklistReference, loadChecksBySiteReference } from "../../lib/firebase/company";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { FaSort, FaSortAlphaDown, FaSortAlphaDownAlt, FaSortAlphaUp, FaTrash } from "react-icons/fa";
import { BiLoaderAlt } from "react-icons/bi";
import Modal from "../../components/Modal";

import { toast } from "react-toastify";

interface SevenDaysTableRowProps {
  checklist: Checklist;
  onDeleted: () => void;
}

const SevenDaysTableRow: React.FC<SevenDaysTableRowProps> = ({ checklist, onDeleted }) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);

  const deleteChecklist = async () => {
    setIsDeleting(true);
    const response = await deleteChecklistBySiteAndChecklistReference(checklist.siteReference.reference, checklist.reference);
    setIsDeleting(false);
    onDeleted();
  };
  return (
    <>
      <tr data-sitereference={checklist.reference}>
        <td>{checklist.siteReference.companyName}</td>

        <td>{checklist.list}</td>
        <td>{checklist.recurrenceType}</td>
        <td className="d-flex">
          <Link to={`/checklist-editor/${checklist.siteReference.reference}/${checklist.reference}`} className="btn-primary grow">
            View
          </Link>
          {isDeleting ? (
            <>
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </>
          ) : (
            <span
              className="ml-2"
              role="button"
              onClick={() => {
                setIsConfirmDeleteOpen(true);
              }}
            >
              <FaTrash />
            </span>
          )}
        </td>
      </tr>
      <Modal visible={isConfirmDeleteOpen} onClose={() => setIsConfirmDeleteOpen(false)} title={`Delete checklist`}>
        <h3>Are you sure you want to delete the {checklist.list} checklist?</h3>
        <div className="row gx-5 mx-auto">
          <Button
            text="Yes"
            onClick={() => {
              deleteChecklist()
                .then((data) => toast.info(`'${checklist.list}' checklist has been removed.`))
                .catch((error) => toast.error(error));

              setIsConfirmDeleteOpen(false);
            }}
          ></Button>

          <Button
            text="Cancel"
            onClick={() => {
              setIsConfirmDeleteOpen(false);
            }}
          ></Button>
        </div>
      </Modal>
    </>
  );
};

const ChecklistsTable: React.FC = () => {
  const sites = useSelector((state: RootState) => state.company.sites);
  const [frequencySortType, setFrequencySortType] = useState<string>("daily");
  const [sortByNameType, setSortByNameType] = useState<string>("asc");
  const [sortBySite, setSortBySite] = useState<string>("asc");

  const [checklists, setChecklists] = useState<Checklist[] | undefined>();

  const fetchCheks = useRef(async () => {
    let rawChecklists: Checklist[] = [];
    for (let x = 0; x < sites.length; x++) {
      const querySnapshot = await loadChecksBySiteReference(sites[x].reference).get();
      querySnapshot.forEach((doc: any) => {
        rawChecklists.push({ ...doc.data(), reference: doc.id, siteReference: sites[x] } as Checklist);
      });
    }
  
    setChecklists(rawChecklists.sort((a, b) => a.siteReference.companyName.localeCompare(b.siteReference.companyName)));
  });
  useEffect(() => {
    fetchCheks.current();
  }, [sites]);
  return (
    <>
      <div
        className="m-0 p-0"
        style={{
          overflowY: "auto",
          height: "280px",
          width: "100%",
        }}
      >
        <table className="table seven-days-table">
          <thead style={{ position: "sticky", top: 0, background: "white" }}>
            <tr>
              <th
                key={0}
                onClick={() => {
                  const newSortType = sortBySite == "asc" ? "desc" : "asc";
                  setSortBySite(newSortType);
                  setChecklists(
                    checklists?.sort((a, b) =>
                      newSortType == "asc"
                        ? a.siteReference.companyName.localeCompare(b.siteReference.companyName)
                        : b.siteReference.companyName.localeCompare(a.siteReference.companyName)
                    )
                  );
                }}
              >
                Site
                {sortBySite != "desc" ? <FaSortAlphaDown /> : <FaSortAlphaDownAlt />}
              </th>
              <th
                key={1}
                onClick={() => {
                  const newSortType = sortByNameType == "asc" ? "desc" : "asc";
                  setSortByNameType(newSortType);
                  setChecklists(checklists?.sort((a, b) => (newSortType == "asc" ? a.list.localeCompare(b.list) : b.list.localeCompare(a.list))));
                }}
              >
                Checklist
                {sortByNameType != "desc" ? <FaSortAlphaDown /> : <FaSortAlphaDownAlt />}
              </th>
              <span
                onClick={() => {
                  const newSortType = frequencySortType == "daily" ? "weekly" : frequencySortType == "weekly" ? "monthly" : "daily";
                  setFrequencySortType(newSortType);

                  setChecklists(
                    checklists!.sort((a, b) => {
                      const aCompare = a.recurrenceType == newSortType ? 1 : -1;
                      const bCompare = b.recurrenceType == newSortType ? 1 : -1;

                      if (aCompare > bCompare) {
                        return -1;
                      }

                      if (bCompare > aCompare) {
                        return 1;
                      }
                      return 0;
                    })
                  );
                }}
              >
                <th key={2}>
                  Frequency
                  <FaSort />
                </th>
              </span>
              <th key={3}>Action</th>
            </tr>
          </thead>
          <tbody className="height: 100px;">
            {checklists && checklists.map((checklist) => <SevenDaysTableRow checklist={checklist} onDeleted={fetchCheks.current}></SevenDaysTableRow>)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ChecklistsTable;
