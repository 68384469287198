import moment from 'moment-timezone';
import { CHECKLISTS } from '../../lib/issues/issuesSlice';

const checkTableSort = (a: string[], b: string[]) => {
  if (a[0] < b[0]) {
    return -1;
  }
  if (a[0] === b[0] && a[6] > b[6]) {
    return -1;
  }
  if (a[0] > b[0]) {
    return 1;
  }
  if (a[0] === b[0] && a[6] < b[6]) {
    return 1;
  }
  return 0;
};

const getCheckType = (issue: any) => {
  if (CHECKLISTS.includes(issue.resolutionType)) {
    return 'checklist';
  }

  return issue.resolutionType;
};

const getCheckName = (issue: any) => {
  if (
    issue.checkType === 'daily checklists' ||
    issue.checkType === 'weekly checklists' ||
    issue.checkType === 'monthly checklists'
  ) {
    return issue.text;
  } else if (issue.checkType === 'deliveries') {
    return issue.type;
  }
  return issue.name;
};

const getCheckTime = (issue: any) => {
  if (
    issue.resolveType === 'dailyChecklist' ||
    issue.resolveType === 'weeklyChecklists' ||
    issue.resolveType === 'monthlyChecklists'
  ) {
    return issue.list?.log?.time
      ? moment.utc(issue.list?.log?.time).format('D MMM YYYY')
      : '';
  } else if (issue.resolveType === 'delivery') {
    return issue.time ? moment.utc(issue.time).format('D MMM YYYY') : '';
  }
  return issue.checks
    .filter((check: any) => !check.passed && check.time)
    .map((check: any) =>
      check.time ? moment.utc(check.time).format('D MMM YYYY') : '',
    )
    .pop();
};

const getCheckTimeForSort = (issue: any) => {
  if (
    issue.resolveType === 'dailyChecklists' ||
    issue.resolveType === 'weeklyChecklists' ||
    issue.resolveType === 'monthlyChecklists'
  ) {
    return issue.list?.log?.time || '';
  } else if (issue.checkType === 'delivery') {
    return issue.time;
  }
  return issue.checks
    .filter((check: any) => !check.passed && check.time)
    .map((check: any) => check.time)
    .pop();
};

const getChecklistTableRow = (name: string, issue: any, timezone: any) => [
  name,
  issue.resolutionType,
  issue.failedItem,
  'Fail',
  issue.loggedBy.join(' / '),
  (issue.logTime || issue.dateTime)
    .map((lt: string) => moment.tz(lt, timezone).format('D MMM YYYY'))
    .join(' / '),
  issue.timestamp,
];

const getDeliveryTableRow = (name: string, issue: any, timezone: any) => [
  name,
  issue.resolutionType,
  issue.failedItem,
  issue.type === 'ambient'
    ? 'Ambient reject'
    : Math.round(issue.failedResult * 10) / 10 + ' °C',
  issue.loggedBy.join(' / '),
  (issue.logTime || issue.dateTime)
    .map((lt: string) => moment.tz(lt, timezone).format('D MMM YYYY'))
    .join(' / '),
  issue.timestamp,
];

const getTemperatureTableRow = (name: string, issue: any, timezone: any) => {
  const failedResults = issue.failedResult.map(
    (res: number) => `${Math.round(res * 10) / 10} °C`,
  );

  if (issue.hasTimeError) {
    failedResults.push(`${issue.timeErrorValue} mins`);
  }

  return [
    name,
    issue.resolutionType,
    issue.failedItem,
    issue.failedResult
      .map((res: number) => `${Math.round(res * 10) / 10} °C`)
      .join(' / '),
    issue.loggedBy.join(' / '),
    issue.dateTime
      .map((lt: string) => moment.tz(lt, timezone).format('D MMM YYYY'))
      .join(' / '),
    issue.timestamp,
  ];
};

const getTimeoutsTableRow = (name: string, issue: any, timezone: any) => [
  name,
  issue.resolutionType,
  issue.failedItem,
  'Time Out',
  'System',
  issue.dateTime
    ?.map((lt: string) => moment.tz(lt, timezone).format('D MMM YYYY'))
    ?.join(' / ') || moment.tz(issue.timestamp, timezone).format('D MMM YYYY'),
  issue.timestamp,
];

export {
  getDeliveryTableRow,
  getChecklistTableRow,
  getTemperatureTableRow,
  getTimeoutsTableRow,
  getCheckTimeForSort,
  checkTableSort,
  getCheckType,
  getCheckTime,
  getCheckName,
};
