import React from "react";
import { createPortal } from "react-dom";
import { PropsWithChildren } from "react";
import c from "classnames";

interface ModalProps extends PropsWithChildren<{}> {
  visible: boolean;
  onClose: () => void;
  title?: string;
  autoHeight?: boolean;
  children: React.ReactNode;
}

const modalDiv = document.getElementById("modal") as Element;

const Modal: React.FC<ModalProps> = ({ visible, onClose, title, children, autoHeight }) => {
  const onModalClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return createPortal(
    <div
      className={c(["overlay", { visible }])}
      style={{
        overflow: "visible",
      }}
      onClick={onClose}
    >
      <div
        className={c(["modal"])}
        onClick={onModalClick}
        style={
          autoHeight ?? false
            ? {
                height: "auto",
                overflow: "visible",
              }
            : {}
        }
      >
        {title && (
          <div className="modal-header">
            <h5>{title}</h5>
          </div>
        )}

        <div className="modal-body">{children}</div>
      </div>
    </div>,
    modalDiv
  ) as React.ReactPortal;
};

export default Modal;
