const USER_KEY: string = '@Hubl:userKey';

interface LocalStorageUserBase {
  uid: string;
  email: string;
  superAdmin: boolean;
  companyEmail: string;
}

type LocalStorageUser = LocalStorageUserBase | null;

export const storeUser = (user: LocalStorageUser) => { 
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const getUser = (): LocalStorageUser => {
  const user = localStorage.getItem(USER_KEY);
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const setCompanyEmail = (companyEmail: string) => {
  const user = localStorage.getItem(USER_KEY);
  if (user) {
    localStorage.setItem(
      USER_KEY,
      JSON.stringify({ ...JSON.parse(user), companyEmail: companyEmail })
    );
  }
};

export const deleteUser = (): void => localStorage.removeItem(USER_KEY);

export const getUid = (): string | null => {
  const user = getUser();
  if (user) {
    return user.uid;
  }
  return null;
};

export const getEmail = (): string | null => {
  const user = getUser();
  if (user) {
    return user.email.toLowerCase();
  }
  return null;
};
