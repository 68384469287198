import { createSlice } from '@reduxjs/toolkit';

export enum Roles {
  SiteManager = 'siteManager',
  RegionManager = 'regionManager',
  CompanyManager = 'companyManager',
  superAdmin = 'superAdmin'
}

const initialState = {
  user: null,
  errorCode: null,
  errorMessage: null,
  isLoggingOut: false,
  isLoggingIn: false,
  role: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logout(state) {
      console.log('logout');
      state.isLoggingOut = true;
      state.user = null;
    },
    loggedOut(state) {
      state.isLoggingOut = false;
    },
    doLogin(state, { payload: { email, password } }) {
      state.isLoggingIn = true;
    },
    setError(state, { payload: { errorCode, errorMessage } }) {
      state.errorCode = errorCode;
      state.errorMessage = errorMessage;
      state.isLoggingIn = false;
    },
    resetErrorCode(state) {
      state.isLoggingIn = false;
      state.errorCode = null;
      state.errorMessage = null;
    },
    userLoggedIn(state, { payload }) { 
      state.user = payload;
      state.isLoggingIn = false;
    },
    setRole(state, { payload }) {
      state.role = payload;
    }
  },
});

export const {
  logout,
  doLogin,
  userLoggedIn,
  loggedOut,
  setError,
  resetErrorCode,
  setRole,
} = loginSlice.actions;

export default loginSlice.reducer;
