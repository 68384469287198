import { useQuill } from "react-quilljs";
import Modal from "../../components/Modal";
import { useState } from "react";
import React from "react";
import Button from "../../components/Button";

import "quill/dist/quill.snow.css";
import { storage } from "../../firebase";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

    [{ color: [] }, { background: [] }],

    ["clean"],
  ],
};

function selectLocalImage(q: any) {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.click();

  // Listen upload local image and save to server
  input.onchange = () => {
    if (input.files) {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        saveToServer(q, file);
      } else {
        console.warn("You could only upload images.");
      }
    }
  };
}

function insertToEditor(q: any, url: string) {
  // push image url to rich editor.
  const range = q.getSelection();
  q.insertEmbed(range!.index, "image", `${url}`);
}

/**
 * Step2. save to server
 *
 * @param {File} file
 */
function saveToServer(q: any, file: File) {
  const fd = new FormData();
  fd.append("file", file);

  // Upload to Firebase Storage
  const storageRef = storage.ref(`images/${file.name}`);
  const uploadTask = storageRef.put(file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Optionally handle the upload progress here
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
    },
    (error) => {
      // Handle any errors during the upload
      console.error("Upload failed: ", error);
    },
    () => {
      // Handle successful upload
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log("File available at", downloadURL);
        insertToEditor(q, downloadURL);
      });
    }
  );
}
const EditDescriptionModal = ({ isOpen, check, onChange, onClose }: { isOpen: any; check: any; onChange: any; onClose: any }) => {
  const { quill, quillRef } = useQuill({ modules: modules });

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(check.description ?? "");
      // quill editor add image handler
      quill!.getModule("toolbar").addHandler("image", () => {
        selectLocalImage(quill);
      });
    }
  }, [quill, check]);

  return (
    <>
      <Modal visible={isOpen} onClose={function (): void {}} title="Edit Description" autoHeight>
        <div className="flex">
          <div
            className="rounded"
            style={{
              height: isExpanded ? 500 : 200,
              maxHeight: isExpanded ? 500 : 200,
              marginBottom: 20,
            }}
          >
            <div
              ref={quillRef}
              style={{
                position: "relative",
              }}
            ></div>
            <span
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              style={{
                position: "absolute",
                right: 20,
                bottom: 135,
                fontSize: 15,
                zIndex: 20,
                cursor: "pointer",
              }}
            >
              {isExpanded ? "Condense" : "Expand"}
            </span>
          </div>
          <div
            style={{
              height: 70,
            }}
          ></div>
          <div className="row g-1 px-3 py-3">
            <Button
              onClick={() => {
                onChange(quill?.root.innerHTML ?? "");
                onClose();
              }}
              text={"Save"}
            />
            <span
              style={{
                width: 10,
              }}
            ></span>
            <Button
              onClick={() => {
                onClose();
              }}
              text={"Close"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditDescriptionModal;
