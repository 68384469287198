enum ISSUE_TYPES {
  CHECKLIST = 'checklist',
  DELIVERY = 'delivery',
  COLD = 'cold',
  HOT = 'hot',
  TIME_OUT = 'time_out',
}

export const ISSUE_GROUP_LABELS = Object.freeze({
  [ISSUE_TYPES.CHECKLIST]: 'Checklists',
  [ISSUE_TYPES.DELIVERY]: 'Deliveries',
  [ISSUE_TYPES.COLD]: 'Cold temperatures',
  [ISSUE_TYPES.HOT]: 'Hot temperatures',
  [ISSUE_TYPES.TIME_OUT]: 'Time outs',
});

export default ISSUE_TYPES;
