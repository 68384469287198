import React from "react";
import { checkTableSort } from "./utils";

interface CheckListTableRowProps {
  key: any;
  id: number;
  issue: any;
}

const CheckListTableRow: React.FC<CheckListTableRowProps> = ({ issue }) => {
  return (
    <tr>
      <td>{issue[0]}</td>
      <td>{issue[1]}</td>
      <td>{issue[2]}</td>
      <td>
        <span className="text-danger">{issue[3]}</span>
      </td>
      <td>{issue[4]}</td>
      <td>{issue[5]}</td>
    </tr>
  );
};

const CheckListTable: React.FC<{ issues: any }> = ({ issues }) => {
  return (
    <table className="table checklist-table">
      <thead>
        <tr>
          <th>Site</th>
          <th>Check type</th>
          <th>Check</th>
          <th>Result</th>
          <th>Logged by</th>
          <th>Logged time</th>
        </tr>
      </thead>
      <tbody>
        {issues.sort(checkTableSort).map((issue: any, index: number) => (
          <CheckListTableRow key={index} issue={issue} id={index} />
        ))}
      </tbody>
    </table>
  );
};

export default CheckListTable;
