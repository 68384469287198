import React, { useState } from "react";
import { Day, Days } from "../../lib/calendar/calendarSlice";
import { default as ReactCalendar } from "react-calendar";
import moment from "moment";

const getOuterStyle = (day: Day) => {
  let style = "day";
  if (day && day.failed > 0 && day.failed === day.resolved) {
    style += " resolvedDay";
  }
  return style;
};

const getInnerStyle = (day: Day) => {
  if (!day || day.items === 0) return;
  if (day.failed === 0 || (day.failed > 0 && day.failed === day.resolved)) {
    return "passedDay";
  }
  return "failedDay";
};

interface CalendarDayProps {
  day: Day;
}

const CalendarDay: React.FC<CalendarDayProps> = ({ day }) => (
  <div className={getOuterStyle(day)}>
    <div className={getInnerStyle(day)} />
  </div>
);

interface CalendarProps {
  days: Days;
}
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const Calendar: React.FC<CalendarProps> = ({ days }) => {
  const [value, onChange] = useState<Value>(new Date());

  return (
    <ReactCalendar
      value={value}
      onChange={onChange}
      minDetail="month"
      showNeighboringMonth={false}
      calendarType="US"
      locale="en-GB"
      tileContent={({ date }) => {
        const day = days[moment(date).format("YYYY-MM-DD")];
        return <CalendarDay day={day} />;
      }}
    />
  );
};

export default Calendar;
