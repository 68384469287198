import React, { useEffect, useState } from "react";
import Box from "../../components/Box";
import Input from "react-select/dist/declarations/src/components/Input";
import Button from "../../components/Button";
import { getUsers, loadNotificationType, notificationsCollection, updateNotificationType } from "../../lib/firebase/notification";
import { DocumentSnapshot } from "rxfire/firestore/interfaces";
import { firestore } from "../../firebase";

export default function NotificationTypes() {
  const [notificationDetails, setNotificationDetails] = useState({
    title: "",
    body: "",
  });

  const handleTextAreaInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>, type: string, field: string) => {
    setNotificationDetails({
      ...notificationDetails,
      body: event.currentTarget.value,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, type: string, field: string) => {
    setNotificationDetails({
      ...notificationDetails,
      title: event.currentTarget.value,
    });
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const users = await getUsers().get();

      users.docs.forEach(async (data) => {
        if (data.data().enabled) {
          const collection = notificationsCollection();
          await collection.set({
            fcmToken: data.data().fcmToken,
            userId: data.id,
            title: notificationDetails.title,
            body: notificationDetails.body,
            createdAt: firestore.Timestamp.now(),
          });
        }
      });
      alert("Notifications sent!");
    } catch (error) {
      console.error("Error sending notifications: ", error);
      alert("Error sending notifications...");
    }
  };
  return (
    <>
      <Box header="Bulk notifications" heigthMin>
        <div className="container my-4 px-0 mx-0">
          <form onSubmit={handleFormSubmit} className="d-flex flex-column gap-5 row p-4">
            <div className="d-flex flex-column align-items-start">
              <span className="fw-bold fs-4 mb-4">Details</span>
              <span className=" mb-4">
                !! All users who have enabled the notifications feature within the app and approved the permissions requested by Android or iOS will
                receive this notification. !!
              </span>
              <div
                className="d-flex flex-column gap-3  "
                style={{
                  width: "100%",
                }}
              >
                <div className="col">
                  <span>Title</span>
                  <input
                    placeholder="Title"
                    style={{
                      width: "100%",
                    }}
                    className="form-control"
                    value={notificationDetails.title}
                    onChange={(e) => handleInputChange(e, "daily", "title")}
                  />
                </div>
                <div className="col">
                  <span>Body</span>
                  <textarea
                    placeholder="Body"
                    className="form-control"
                    rows={3} // Itt állíthatjuk be a sorok számát
                    value={notificationDetails.body}
                    onChange={(e) => handleTextAreaInputChange(e, "daily", "body")}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-end mt-auto">
              <input type="submit" className="btn btn-primary btn-xl mt-3" value="Save" />
            </div>
          </form>
        </div>
      </Box>
    </>
  );
}
