import React from "react";
import { PropsWithClassAndChildren } from "../common/types";
import c from "classnames";

const Container: React.FC<PropsWithClassAndChildren<{}>> = ({
  children,
  className
}) => {
  return (
    <div className={c(["non-bootstra-container", className])}>{children}</div>
  );
};

export default Container;
