import { ofType, combineEpics } from 'redux-observable';
import {
  switchMap,
  map,
  ignoreElements,
  tap,
  catchError,
} from 'rxjs/operators';
import { defer } from 'rxjs';

import { auth } from '../../firebase';
import {
  logout,
  doLogin,
  userLoggedIn,
  loggedOut,
  setError as cannotLoggedIn,
} from './loginSlice';
import { storeUser, deleteUser } from '../../utils/storage';
import { toast } from 'react-toastify';

const doLoginEpic = (action$) =>
  action$.pipe(
    ofType(doLogin.toString()),
    switchMap(({ payload }) =>
      defer(async () => {
        try {
          const { user } = await auth.signInWithEmailAndPassword(
            payload.email,
            payload.password,
          ); 
          const { claims } = await user.getIdTokenResult(true); 
          return userLoggedIn({
            uid: user.uid,
            email: user.email,
            superAdmin: claims.superAdmin
          });
        } catch (error) {
          toast.error(error.message);

          return cannotLoggedIn({
            errorCode: error.code,
            errorMessage: error.message,
          });
        }
      }),
    ),
    catchError((error) => console.log(error)),
  );

const logoutEpic = (action$) =>
  action$.pipe(
    ofType(logout.toString()),
    tap(() => deleteUser()),
    tap(() => (window.location = '/login')),
    map(() => loggedOut()),
  );

const storeLoginDetails = (action$, state) =>
  action$.pipe(
    ofType(userLoggedIn.toString()),
    tap(() => storeUser(state.value.login.user)),
    tap(() => state.value.login.user.superAdmin ? window.location = '/select-company' : window.location = '/'),
    ignoreElements(),
  );

export default combineEpics(doLoginEpic, storeLoginDetails, logoutEpic);
